import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Copywriting from './components/copywriting';
import Marketing from './components/marketing';
import Seo from './components/seo';
import Productivity from './components/productivity';
import SocialMedia from './components/socialMedia';
import Professionals from './components/professionals';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StwAiGrowthHacker from './components/stwAiGrowthHacker';
import FreelancerTemplate from './components/freelancer';
import axios from 'axios';
import CookieService from './cookies/cookiesService'
const Template: any[] = [{
    "Blog Writing":[
        "Generate Blog Post Titles",
        "Generate Blog Post Descriptions",
        "Generate Blog Post Outline",
        "Generate Complete Blog Post From Outline",
        "Generate Complete Blog Post From Topic",
        "Generate Introduction Using Framework",
        "Generate Paragraph Of Text"
    ],
    "Book Writing":[
        "Plot Generator",
        "Book Title Generator",
        "Book Outline Generator",
        "Book Chapter Generator"
    ],
    "Content Writing": [
        "Content Titles Generator",
        "Content Rewriter (Paste Content)",
        "Content Brief Generator",
        "Content Outline Generator",
        "Monthly Content Calendar",
        "FAQ Generator",
        "Article to Listicle",

    ],
    "Course Writing": [
        "Course Idea Generator",
        "Course Outline Generator",
        "Complete Course Lesson",
        "Course Assignment Generator"
    ],
    "Email Writing": [
        "Professional Email Editor",
        "Marketing Email Generator",
        "Sales Prospecting Email",
        "Cold Outreach Email",
        "Follow-up Email",
        "Referral Email Generator",
        "Subject Lines Generator",
        "Email Newsletter Calendar"
    ],
    "Landing Pages": [
        "Generic Landing Page",
        "Landing Page for Ecommerce",
        "Landing Page for Affiliates",
        "Landing Page for SaaS Tool"
    ],
    "Frameworks": [
        "AIDA Framework",
        "PAS Framework",
        "BAB Framework",
        "FAB Framework"
    ],
    "Miscellaneous": [
        "Create Buyer Persona",
        "Create Long-Form Sales Letter",
        "Create Video Sales Letter (VSL)"
    ],
    "Keyword Research":[
        "Keyword Strategy",
        "Get Search Intent For Keywords",
        "Related Keyword Generator",
        "Long-Tail Keyword Generator"
    ],
    "Ecommerce SEO": [
        "Product Description Generator",
        "Category Description Generator",
        "Landing Page Generator",
        "Bulk Titles and Descriptions"
    ], 
    "Local SEO": [
        "Optimize Your Google Business Profile (GMB)",
        "Attributes for Google Business Profile (GMB)",
        "Generate Google Business Profile Posts",
        "Generate Q &A for Google Business Profile",
        "Generate GMB Post from Content"
    ],
    "On-Page Optimization": [
        "Meta Title &Description Generator",
        "Create Silo Structure",
        "Insert Keywords into Content"
    ],
    "Facebook": [
        "Facebook Post Ideas",
        "Facebook Group Post",
        "Facebook Ad Creator",
        "Facebook Post Calendar"
    ],
    "Instagram":[
        "Generate Instagram Carousel",
        "Generate Instagram Caption",
        "Instagram Post Calendar"

    ],
    "LinkedIn": [
        "LinkedIn Post Creator",
        "LinkedIn Comment Generator",
        "LinkedIn Bio Generator",
        "LinkedIn Connection Message",
        "LinkedIn Ad Generator",
        "LinkedIn Hashtag Generator",
        "LinkedIn Post Calendar"

    ],
    "Pinterest": [
        "Keywords For Pinterest",
        "Generate Pin Titles",
        "Generate Pin Descriptions",
        "Pinterest Hashtag Generator",
        "Pinterest Pin Calendar"
    ],
    "TikTok": [
        "TikTok Video Ideas",
        "TikTok Script Writer",
        "TikTok Hashtag Generator",
        "TikTok Post Calendar"
    ],
    "Twitter": [
        "Engaging Twitter Thread",
        "Twitter Personalized Bio",
        "Convert Article Into Twitter Thread",
        "Respond to Customers on Twitter",
        "Twitter Hashtag Generator",
        "Twitter Thread Calendar"
    ],
    "YouTube": [
        "YouTube Script Creator",
        "YouTube Shorts Script",
        "YouTube Title &Descriptions",
        "YouTube Transcript to Article",
        "YouTube Ads Generator",
        "YouTube Tags Generator",
        "YouTube Video Calendar"
    ],
    "Excel": [
        "Excel Dictionary",
        "Excel Helper",
        "Excel Macro"
    ],
    "Translation":[
        "Native Translator",
        "Language Detector",
        "Expert Language Translator"
    ],
    "Extraction":[
        "Keywords Extraction",
        "Extractive Summary",
        "Topic Extraction"
    ],
    "Summarization":[
        "Abstractive &Extractive",
        "Bullet Point Summary",
        "TLDR Summary",
        "Research Paper Summary",
        "Structured Academic Summary"
    ],
    "Accountants":[
        "Financial Planning",
        "Tax Accounting",
        "Regulatory Compliance",
        "Accounting Dictionary"
    ],
    "Entrepreneurs":[
        "Business Auditor",
        "Business Idea Generator",
        "Marketing Manager",
        "Business Advisor"
    ],
    "Finance":[
        "Investment Manager",
        "Excel Macro Helper",
        "Financial Dictionary"
    ],
    "Human Resources":[
        "Interview Questions Generator",
        "Employee Retention and Engagement",
        "Employee Development Plan",
        "Employee Performance Evaluation"
    ],
    "Lawyers":[
        "Case Law Generator",
        "Risk and Liabilities Generator",
        "Legal Research Helper",
        "Legal Precedents ",
        "Document Analyst",
        "Drafting Settlement Agreement"
    ],
    "Teachers":[
        "Quiz Creator",
        "Subject Overview",
        "Resource Generator",
        "Lesson Plan Calendar"
    ]
}]

const TemplateSTWAi: any[] = [{
    "Goal":[
        "Goal Setting"
    ],
    "Marketing":[
        "Targeted Customers",
        "Marketing Strategy",
        "Content Marketing"
    ],
    "Sales":[
        "Sale Meeting Frame work",
        "Powerful Sale Presentation Tips",
        "Case Studies",
        "Sale Proposal",
        "Meeting Script"
    ],
    "Operations":[
        "Team Structure",  
        "Process Automation Adviser",
        "Resume Assessment",
        "Question for Interview"  
    ]
}]
const TemplateFreelancer: any[] = [
    "Profile Title",
    "Profile Description",
    "Portfolio Creation",
    "Proposal Writing",
    "Requirement Gathering",
    "Instructor",
    "Communication Coach",
    "Price Advisor",
    "Estimate",
    "Case Studies"
]

function App() {
    const [templateData]=useState(Template[0])
    const [templateSTWAiData]=useState(TemplateSTWAi[0])
    const [categoryValue, setCategoryValue] = useState('')
    const [subcategory, setSubcategory] = useState([])
    const [subcategoryValue, setSubcategoryValue] = useState('')
    const [categorySTWAiValue, setCategorySTWAiValue] = useState('')
    const [subSTWAicategory, setSTWAiSubcategory] = useState([])
    const [subSTWAicategoryValue, setSTWAiSubcategoryValue] = useState('')
    const [subFreelancercategory, setFreelancerSubcategory] = useState([])
    const [subFreelancercategoryValue, setFreelancerSubcategoryValue] = useState('')
    const [template, setTemplate] = useState([])
    const [templateSTWAi, setTemplateSTWAi] = useState([]);
    const [templateValue, setTemplateValue] = useState('')
    const [templateSTWAiValue, setTemplateSTWAiValue] = useState('')
    const [templateFreelancerValue, setTemplateFreelancerValue] = useState('')
    const [freelancerData, setFreelancerData] = useState('');

    const executeTemplateButton = (prompt: any, keyword: any) => {
        prompt && postdata('choose-template-post', {
            prompt: prompt, 
            kayword: keyword
        });
        prompt && postdata('keyword', {kayword:keyword});
        setSubcategory([])
        setTemplate([])
    };
    const searchParams = new URLSearchParams(document.location.search).get("id")
    const accessToken= new URLSearchParams(document.location.search).get("accessToken")
    let roles = searchParams ? JSON.parse(searchParams) : [];
    let findFreelancer = roles && roles.find((item: string) => item === 'Freelancer')
    if(accessToken){
        axios.defaults.headers.common = {'Authorization': `bearer ${accessToken}`}
    }
    useEffect(() => {
        const Freelancer = localStorage.getItem('freelancer');
        const Cookie = CookieService.get('freelancer')
        console.log('Cookie', Cookie)
        if(!Freelancer){
            axios.get('https://www.stw-ai.com/DesktopModules/STWAI/API/FreelancerProfile/GetFreelancerProfile')
            .then(({data}) => {
                const cdata=`${JSON.stringify(data.Data)}`
                setFreelancerData(cdata)
                localStorage.setItem('freelancer', cdata)
                CookieService.set('freelancer',cdata,{ path: '/' })
                console.log(CookieService.get('freelancer')); // expected output: username=Debra White; userId=wjgye264s
            })
            .catch((error:any) => {
                if(error.response.data.Message == "Authorization has been denied for this request."){
                    postdata('accessToken', {});
                }
            })
        }else{
            setFreelancerData(Freelancer)
        }
    }, [findFreelancer, accessToken])
    function getCookie(cName: string) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie); //to be careful
        const cArr = cDecoded .split('; ');
        let res;
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length);
        })
        return res;
    }
    const onChangeCategory = (event: any) => {
        setSubcategoryValue('')
        setTemplateValue('')
        const value = event.target.value;
        setCategoryValue(value)
        let category: any = []
        switch(value) {
            case 'Copywriting':
                category = [ 
                    "Blog Writing",
                    "Book Writing",
                    "Content Writing",
                    "Course Writing",
                    "Email Writing",
                    "Landing Pages"
                ]
                setSubcategory(category)
              break;
            case 'Marketing':
                category = [
                    "Frameworks",
                    "Miscellaneous"
                ]
                setSubcategory(category)
              break;
            case 'SEO':
                category = [
                    "Keyword Research",
                    "Ecommerce SEO",
                    "Local SEO",
                    "On-Page Optimization"
                ]
                setSubcategory(category)
                break;
            case 'Social Media':
                category = [
                    "Facebook",
                    "Instagram",
                    "LinkedIn",
                    "Pinterest",
                    "TikTok",
                    "Twitter",
                    "YouTube"
                ]
                setSubcategory(category)
                break;
            case 'Productivity':
                category = [
                    "Excel",
                    "Translation",
                    "Extraction",
                    "Summarization"
                ]
                setSubcategory(category)
                break;
            case 'Professionals':
                category = [
                    "Accountants",
                    "Entrepreneurs",
                    "Finance",
                    "Human Resources",
                    "Lawyers",
                    "Teachers"
                ]
                setSubcategory(category)
                break;
            default: 
                category=[]
        }
    }

    const onChangeSTWAiCategory = (event: any) => {
        setSTWAiSubcategoryValue('')
        setTemplateSTWAiValue('')
        const value = event.target.value;
        setCategorySTWAiValue(value)
        let category: any = []
        switch(value) {
            case 'STW AI Growth Hacker':
                category = ["Goal","Marketing","Sales","Operations"]
                setSTWAiSubcategory(category)
              break;
            default: 
                category=[]
        }
    }

    const onChangeFreelancerCategory = (event: any) => {
        setFreelancerSubcategoryValue('')
        setTemplateSTWAiValue('')
        const value = event.target.value;
        setCategorySTWAiValue(value)
        let category: any = []
        switch(value) {
            case 'Freelancer':
                category = [
                    "Profile Title",
                    "Profile Description",
                    "Project Portfolio",
                    "Proposal Writing",
                    "Requirement Gathering",
                    "Instructor",
                    "Communication Advisor",
                    "Price Advisor",
                    "Case Studies"
                ]
                setFreelancerSubcategory(category)
              break;
            default: 
                category=[]
        }
    }

    var getURLParam = function(sParam :any, locationObj: any) {
        if (!locationObj) locationObj = document.location;
        var sPageURL = locationObj.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++)
        {
          var sParameterName = sURLVariables[i].split('=');
          if (sParameterName[0] == sParam)
          {
            return sParameterName[1];
          }
        }
    };
    useEffect(() => {
        postdata('widget.ready', null);
        postdata('get-settings', null);
        const location = window.location;
        var source = getURLParam('source', location);
        var style: any = window.getComputedStyle(document.body);
        var marginH = parseInt(style['margin-top']) + parseInt(style['margin-bottom']);
        //const findEmpaty = document.body.textContent.replace(/\s*/g, '') === ''
        var isEmpty:any = '';
        if (document.body.querySelector('img')) isEmpty = false;
        var offsetHeight = document.body.offsetHeight;
        if (source === 'freeuser' && !offsetHeight) offsetHeight = 40;
        var data = {
        height: offsetHeight + marginH,
        isEmpty: isEmpty,
        source: source
        };
        window.parent.postMessage({cmd: 'xt.resize', data: data}, '*');
    },[])

    const onChangeSubcategory = (event: any) => {
        setTemplateValue('')
        const value = event.target.value;
        setSubcategoryValue(value)
        if(value && value !== "Choose a Subcategory"){
            const data: any = templateData && templateData[value]
            setTemplate(data)
        } else {
            return;
        }
    }
    const onChangeSTWAiSubcategory = (event: any) => {
        setTemplateValue('')
        const value = event.target.value;
        setSTWAiSubcategoryValue(value)
        if(value && value !== "Choose a Subcategory"){
            const data: any = templateSTWAiData && templateSTWAiData[value]
            setTemplateSTWAi(data)
        } else {
            return;
        }
    }
    const onChangeTemplate = (event: any) => {
        const value = event.target.value;
        setTemplateValue(value !== 'Select a template' ? value : '')
        const location = window.location;
        var source = getURLParam('source', location);
        var style: any = window.getComputedStyle(document.body);
        var marginH = parseInt(style['margin-top']) + parseInt(style['margin-bottom']);
        //const findEmpaty = document.body.textContent.replace(/\s*/g, '') === ''
        var isEmpty:any = '';
        if (document.body.querySelector('img')) isEmpty = false;
        var offsetHeight = document.body.offsetHeight;
        if (source === 'freeuser' && !offsetHeight) offsetHeight = 40;
        var data = {
        height: offsetHeight + marginH,
        isEmpty: isEmpty,
        source: source
        };
        window.parent.postMessage({cmd: 'xt.resize', data: data}, '*');
    }
    const onChangeSTWAiTemplate = (event:any) => {
        const value = event.target.value;
        setTemplateSTWAiValue(value !== 'Select a template' ? value : '')
        const location = window.location;
        var source = getURLParam('source', location);
        var style: any = window.getComputedStyle(document.body);
        var marginH = parseInt(style['margin-top']) + parseInt(style['margin-bottom']);
        //const findEmpaty = document.body.textContent.replace(/\s*/g, '') === ''
        var isEmpty:any = '';
        if (document.body.querySelector('img')) isEmpty = false;
        var offsetHeight = document.body.offsetHeight;
        if (source === 'freeuser' && !offsetHeight) offsetHeight = 40;
        var data = {
        height: offsetHeight + marginH,
        isEmpty: isEmpty,
        source: source
        };
        window.parent.postMessage({cmd: 'xt.resize', data: data}, '*');
    }
    const onChangeFreelanerTemplate = (event:any) => {
        const value = event.target.value;
        setTemplateFreelancerValue(value !== 'Select a template' ? value : '')
        const location = window.location;
        var source = getURLParam('source', location);
        var style: any = window.getComputedStyle(document.body);
        var marginH = parseInt(style['margin-top']) + parseInt(style['margin-bottom']);
        //const findEmpaty = document.body.textContent.replace(/\s*/g, '') === ''
        var isEmpty:any = '';
        if (document.body.querySelector('img')) isEmpty = false;
        var offsetHeight = document.body.offsetHeight;
        if (source === 'freeuser' && !offsetHeight) offsetHeight = 40;
        var data = {
        height: offsetHeight + marginH,
        isEmpty: isEmpty,
        source: source
        };
        window.parent.postMessage({cmd: 'xt.resize', data: data}, '*');
    }
    const postdata = (cmd: string, data: any) => {
        data && window.parent.postMessage({cmd: `xt-openai-${cmd}`, data: data}, '*');
    };
    return (       
        <div className="App">
            <Tabs
                defaultActiveKey="defaultcategory"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="defaultcategory" title="Content Creator">
                    <div className="row">
                        <div className="col-sm-4">
                            <Form.Select 
                                aria-label="Choose a category"
                                value={categoryValue}
                                onChange={onChangeCategory}
                            >
                                <option>Choose a category</option>
                                <option value="Copywriting">Copywriting</option>
                                <option value="Marketing">Marketing</option>
                                <option value="SEO">SEO</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Productivity">Productivity</option>
                                <option value="Professionals">Professionals</option>
                            </Form.Select>   
                        </div>
                        <div className="col-sm-4">
                            <Form.Select 
                                aria-label="Choose a Subcategory" 
                                value={subcategoryValue} 
                                disabled={subcategory.length === 0}
                                onChange={onChangeSubcategory}
                            >
                                <option>Choose a Subcategory</option>
                                {subcategory.map((item, i) => <option key={i} value={item}>{item}</option>)}
                            </Form.Select>   
                        </div>
                        <div className="col-sm-4">
                            <Form.Select 
                                aria-label="Select a template"
                                value={templateValue} 
                                disabled={template.length === 0}
                                onChange={onChangeTemplate}
                            >
                                <option>Select a template</option>
                                {template.map((item, i) => <option key={i} value={item}>{item}</option>)}
                            </Form.Select>   
                        </div>
                    </div>
                    {categoryValue==="Copywriting" && templateValue && <Copywriting templateValue={templateValue} executeTemplateButton={executeTemplateButton} />}
                    {categoryValue==="Marketing" && templateValue && <Marketing templateValue={templateValue} executeTemplateButton={executeTemplateButton} /> }
                    {categoryValue==="SEO" && templateValue && <Seo templateValue={templateValue} executeTemplateButton={executeTemplateButton} />}
                    {categoryValue==="Productivity" && templateValue && <Productivity templateValue={templateValue} executeTemplateButton={executeTemplateButton} />}
                    {categoryValue==="Social Media" && templateValue && <SocialMedia templateValue={templateValue} executeTemplateButton={executeTemplateButton} />}
                    {categoryValue==='Professionals' && templateValue && <Professionals templateValue={templateValue} executeTemplateButton={executeTemplateButton} />}
                </Tab>
                {roles.map((role: string, i:number) => (  
                    role === "Business" || role === "Administrators" ? <Tab key={i}  eventKey="premiumcategory" title="Business Growth Model">
                        <div className="row">
                            <div className="col-sm-4">
                                <Form.Select
                                    aria-label="Choose a category"
                                    value={categorySTWAiValue}
                                    onChange={onChangeSTWAiCategory}
                                >
                                    <option>Choose a category</option>
                                    <option value="STW AI Growth Hacker">STW AI Growth Hacker</option>
                                </Form.Select>
                            </div>
                            <div className="col-sm-4">
                                <Form.Select
                                    aria-label="Choose a Subcategory"
                                    value={subSTWAicategoryValue}
                                    disabled={subSTWAicategory.length === 0}
                                    onChange={onChangeSTWAiSubcategory}
                                >
                                    <option>Choose a Subcategory</option>
                                    {subSTWAicategory.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </Form.Select>
                            </div>
                            <div className="col-sm-4">
                                <Form.Select
                                    aria-label="Select a template"
                                    value={templateSTWAiValue}
                                    disabled={templateSTWAi.length === 0}
                                    onChange={onChangeSTWAiTemplate}
                                >
                                    <option>Select a template</option>
                                    {templateSTWAi.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </Form.Select>
                            </div>
                        </div>
                        {categorySTWAiValue === "STW AI Growth Hacker" && templateSTWAiValue && <StwAiGrowthHacker templateValue={templateSTWAiValue} executeTemplateButton={executeTemplateButton} />}
                    </Tab> : <></>
                ))}
                
                {roles.map((role: string, i:number) => (  
                    role === "Freelancer" || role === "Administrators" ? <Tab key={i} eventKey="freelancercategory" title="Freelancer">
                        <div className="row">
                            <div className="col-sm-4">
                                <Form.Select
                                    aria-label="Select a template"
                                    value={templateFreelancerValue}
                                    onChange={onChangeFreelanerTemplate}
                                >
                                    <option>Select a template</option>
                                    {TemplateFreelancer.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </Form.Select>
                            </div>
                        </div>
                        {templateFreelancerValue && <FreelancerTemplate data={freelancerData} templateValue={templateFreelancerValue} executeTemplateButton={executeTemplateButton} />}
                    </Tab> : <></>
                ))}
            </Tabs>
        </div>
    );
}

export default App;