import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

function FreelancerTemplate(props:any){
    const [freelancerData, setFreelancerData] = useState(JSON.parse(props.data))
    const [radioValue, setradioValue] = useState('English')
    const [selectedValue, setSelectedValue] = useState("English");
    const [languagesData, setLanguagesData] = useState([]);
    const [indianlanguagesData, setIndianLanguagesData] = useState([]);
    const [languagesValue, setLanguagesValue] = useState(radioValue);
    const [voicetonesData, setVoicetonesData] = useState([])
    const [voicetonesValue, setVoicetonesValue] = useState('')
    const [writingStylesData,setwritingStylesData] = useState([])
    const [writingStylesValue,setwritingStylesValue] = useState('')
    const [textareaValue, setTextareaValue] = useState('')
    const [aboutMeValue, setAboutMeValue] = useState('')
    const [expertiseValue, setExpertiseValue] = useState('')
    const [yearsInNumberValue, setYearsInNumberValue] = useState('')
    const [selectProject, setSelectProject] = useState([])
    const [selectProjectValue, setSelectProjectValue] = useState('')
    const [projectNameValue, setProjectNameValue] = useState('')
    const [projectDetailsValue, setProjectDetailsValue] = useState('')
    const [skillsValue, setSkillsValue] = useState('')
    const [clientRequirementValue, setClientRequirementValue] = useState('')
    const [projectValue, setProjectValue] = useState('')
    const [project2Value, setProject2Value] = useState('')
    const [project3Value, setProject3Value] = useState('')
    const [project4Value, setProject4Value] = useState('')
    const [myUnderstandingValue, setMyUnderstandingValue] = useState('')
    const [purposedSolutionValue, setPurposedSolutionValue] = useState('')
    const [problemStatementValue, setProblemStatementValue] = useState('')
    const [communicationDetailsValue, setCommunicationDetailsValue] = useState('') 
    const [myOpinionValue, setMyOpinionValue] = useState('')
    const [clientAvgRateValue, setClientAvgRateValue] = useState('')
    const [areYouTotallyFresherValue, setAreYouTotallyFresherValue] = useState("false")
    const [areYouTotallyFresherToggleValue, setAreYouTotallyFresherToggleValue] = useState('No')
    const [clientProjectTitle, setClientProjectTitle]=useState('');
    const [clientProjectSummary, setClientProjectSummary]=useState('');
    const [clientProjectObjectives, setClientProjectObjectives]=useState('');
    const [clientSkillsRequired, setClientSkillsRequired]=useState('');
    const [caseStudiesDescription, setCaseStudiesDescription]=useState('');
    const [keyword, setKeyword] = useState('')
    const promptTitle = `# Prompt by STW, Corp. - https://www.stw-ai.com/\nLines starting with # are only comments for humans\nYou must add at the top of the response \"_Created with [STW Prompt \"${props.templateValue}\"](https://www.stw-ai.com/)_\"\n---\n`
    let copyWritingPrompt: any ={
        'Profile Title': `${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing./\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\n Do not self-reference. My am expert in "${expertiseValue}" I have ${yearsInNumberValue} years of experience. /\nMy work details are here - /\n${aboutMeValue}/\nPlease write a upwork title which should be Specific,Highlight Unique Selling Points according to my skills set, add Keywords, Keep it Concise, also it should show value and Avoid Jargon.`, 
        'Profile Description': `${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing./\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\n Do not self-reference. My am expert in "${expertiseValue}" I have ${yearsInNumberValue} years of experience. My work details are here - /\n${aboutMeValue}/\nPlease write a upwork Description  minimum 400 words also highlight bullet points which should be Specific,Highlight Unique Selling Points according to my skills set, add Keywords, also it should show value and Avoid Jargon.  `, 
        'Portfolio Creation': `${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing./\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\n Do not self-reference. I have done this project in "". For Client - /\nHere is my project details - /\nProject Name : ${projectNameValue}/\nProject Description : ${projectDetailsValue} /\nBased on project details please help me to write/\nProject name./\nThen do reverse analysis and write a problem statement based on project details in two 150 words./\nThen based on project details write a solution statement in 150 words./\nThen write technology used and language used.`, 
        'Proposal Writing': `${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. /\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\nDo not self-reference. Act as you are a ${expertiseValue}.   You have a technical tone of voice.  You have an informative writing style. You are an expert ${expertiseValue} who has expertise in ${skillsValue}./\nHere is client job detail  - /\n${clientRequirementValue} /\nHere is my details -  /\nI have expertise in ${expertiseValue} with ${yearsInNumberValue}./\nAbout me - /\n${aboutMeValue}/\nPlease find below some of my notable projects and achievements:/\n${projectValue}/\nWrite a Upwork proposal which includes first summarized requirement details, then if anything not clear in requirement write question related to chat as technical expert./\nThen write a technical solution on high level./\nThen write about matching projects given project details./\nThen write about me to show my credibility./\nMake sure whenever you are writing anything not use hyper use link URL instead. And always start with "Dear Client" or whatever makes more sense as proposal.`, 
        'Instructor': `${promptTitle}Please ignore all previous instructions. Please respond only in the ${languagesValue} language. Do not explain what you are doing. /\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\nDo not self-reference. Act as ${expertiseValue} Expert.  /\nYou have deep experience in ${skillsValue}.  Your writing style is informative. Also give reference of some YouTube link or article link which can support your logic in form of link not in form of hyper link.`, 
        'Communication Coach': `${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing./\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\n Do not self-reference. Act as my communication coach./\nHere was client requirement - /\n${clientRequirementValue}/\nHere is communication details -  /\n${communicationDetailsValue}/\nHere is my opinion - /\n${myOpinionValue}/\nYou read all books "How to talk to anyone", "Surrounded by Idiots", "Influence: Science and Practice". Best on all those knowledge please write a reply which also reflect my idea.`, 
        'Price Advisor': `${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing./\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\n Do not self-reference./\nClient Avg Rate Paid : /\n${clientAvgRateValue}/\nMy Skill : /\n${skillsValue}/\nAre you totally fresher - ${areYouTotallyFresherToggleValue} /\nConsider a lower price as a chance to win a project as a fresher. But as experience suggests, consider market completion in freelancing. Please wire the best hourly rate for Indian freelancers on Upwork.`, 
        'Estimate':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing./\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\nDo not self-reference./\nDo not explain what you are doing./\nBelow is Project Description /\nProject Title: /\n${clientProjectTitle} /\nProject Summary: /\n${clientProjectSummary} /\nProject Objectives:/\n${clientProjectObjectives} /\nSkills Required: /\n${clientSkillsRequired} /\nGive me the estimates in tabular format. Estimate table should have Columns like Modules, Task , Description, Hours. `,
        'Case Studies':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing./\nYou have a ${voicetonesValue} tone of voice. You have a ${writingStylesValue} writing style./\nDo not self-reference./\nDo not explain what you are doing./\nAct as a senior content writer specializing in B2B sales. You have written many case studies for b2b companies. You value direct, simple, and clear writing. Your task is to write case studies by extracting details from a given description and present them in a clear, direct, and straightforward way. /\nWrite a case study for the description below /\n${caseStudiesDescription} /\nPlease note the following points while writing a case study- /\nFirst write client information based on given details. Then write provider information based on given details. Then write problem statement in detail, then write solution provided by provider in detail. Then write results in terms of tangible and intangible numbers. Then write call to action. User your sense and best knowledge while writing case study.`
    }
    let templateValue: any = copyWritingPrompt[props.templateValue] ? copyWritingPrompt[props.templateValue] : ''
    useEffect(() => {
        props.templateValue === 'Profile Title' && setKeyword('Profile Title');
        props.templateValue === 'Profile Description' && setKeyword('Profile Description');
        //props.templateValue === 'Portfolio Creation' && setKeyword(projectDetailsValue);
        setTextareaValue(templateValue)
    }, [templateValue])
    useEffect(() => {
        resetValue()
        if(freelancerData)
        setAboutMeValue(freelancerData.AboutMe)
        setSkillsValue(JSON.stringify(freelancerData.Skills))
        setExpertiseValue(JSON.stringify(freelancerData.Expertise))
        setYearsInNumberValue(freelancerData.YearsOfExperience)
        setSelectProject(freelancerData.Projects)
    }, [props.templateValue])
    useEffect(() => {
        if(freelancerData){
            const projectList = freelancerData.Projects
            if(projectList){
                const project: any = []
                projectList.map((item: any, i: number) => {
                    const projectDetails = `/\nProject Name: ${item.ProjectName}/\nDescription: ${item.Description}/\nStart Date: ${item.StartDate}/\nEnd Date: ${item.EndDate}/\nProject URL: ${item.ProjectURL}/\nRole In Project: ${item.RoleInProject}/\nTechnologies Used: ${item.TechnologiesUsed}/\n`
                    project.push({['project'+[i+1]]:projectDetails})
                })
                const setData = project && JSON.stringify(project)
                setProjectValue(setData)
            }
        }
        const data: any = []
        for (const key in indianLanguages) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = indianLanguages[key];
            data.push(value)
        }
        setIndianLanguagesData(data) 
        const dataVoice: any = []
        for (const key in voicetones) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = voicetones[key];
            dataVoice.push(value)
        }
        setVoicetonesData(dataVoice) 
        const datawritingStyles:any = []
        for (const key in writingStyles) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = writingStyles[key];
            datawritingStyles.push(value)
        }
        setwritingStylesData(datawritingStyles)
        if(freelancerData)
        setAboutMeValue(freelancerData.AboutMe)
        setSkillsValue(JSON.stringify(freelancerData.Skills))
        setYearsInNumberValue(freelancerData.YearsOfExperience)
        setSelectProject(freelancerData.Projects)
    },[])
    const onChangeRadio = (event: any) => {
        const value = event.target.value;
        setSelectedValue(value);
        setradioValue(value)
        if(value === 'Other languages'){
            const data: any = []
            for (const key in languages) {
                const value: any = {
                    name: '',
                    value: ''
                }
                value.value = key;
                value.name = languages[key];
                data.push(value)
            }
            setLanguagesData(data)
            setLanguagesValue('Arabic')
        } else {
            setLanguagesValue(value)
            setLanguagesData([])
        }
    }
    const onChangeLanguages = (event: any) => {
        const value = event.target.value;
        setLanguagesValue(value)
    }
    const onChangeVoicetones = (event: any) => {
        const value = event.target.value
        setVoicetonesValue(value)
    }
    const onChangeWritingStyles = (event: any) => {
        const value = event.target.value
        setwritingStylesValue(value)
    }
    const onChangeAboutMe = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setAboutMeValue(value)
    }
    const onChangeExpertise = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setExpertiseValue(value)
    }
    const onChangeYearsInNumber = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setYearsInNumberValue(value)
    }
    const onChangeSelectProjectValue = (event: any) => {
        const value = event.target.value
        const finsProject:any = selectProject.find((item:any) => item.ProjectID == value)
        setSelectProjectValue(value)
        setProjectNameValue(finsProject.ProjectName)
        setProjectDetailsValue(finsProject.Description)
        setKeyword(finsProject.Description)
    }
    const onChangeProjectName = (event: any) => {
        const value = event.target.value  
        setProjectNameValue(value)
    }
    const onChangeProjectDetails = (event: any) => {
        const value = event.target.value  
        setProjectNameValue(value)
    }
    const onChangeSkills = (event: any) => {
        const value = event.target.value  
        setSkillsValue(value)
    }
    const onChangeClientRequirement = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setClientRequirementValue(value)
    }
    const onChangeProject1 = (event:any) => {
        const value = event.target.value
        setProjectValue(value)
    }
    const onChangeProject2 = (event:any) => {
        const value = event.target.value
        setProject2Value(value)
    }
    const onChangeProject3 = (event:any) => {
        const value = event.target.value
        setProject3Value(value)
    }
    const onChangeProject4 = (event:any) => {
        const value = event.target.value
        setProject4Value(value)
    }
    const onChangeMyUnderstanding = (event:any) => {
        const value = event.target.value
        setMyUnderstandingValue(value)
    }
    const onChnagePurposedSolution = (event:any) => {
        const value = event.target.value
        setPurposedSolutionValue(value)
    }
    const onChnageProblemStatement = (event: any) => {
        const value = event.target.value
        setKeyword(value)
        setProblemStatementValue(value)
    }
    const onChnageCommunicationDetails = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setCommunicationDetailsValue(value)
    }
    const onChnageMyOpinion = (event: any) => {
        const value = event.target.value
        setKeyword(value)
        setMyOpinionValue(value)
    }
    const onChangeClientAvgRate = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setClientAvgRateValue(value)
    }
    const onChnageClientProjectTitle = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setClientProjectTitle(value)
    }
    const onChnageClientProjectSummary = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setClientProjectSummary(value)
    }
    const onChnageClientProjectObjectives = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setClientProjectObjectives(value)
    }
    const onChnageClientSkillsRequired = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setClientSkillsRequired(value)
    }
    const onChnageCaseStudiesDescription = (event:any) => {
        const value = event.target.value
        setKeyword(value)
        setCaseStudiesDescription(value)
    }
    const onChangeAreYouTotallyFresher = (event:any) => {
        const value = event.target.checked
        const toggleValue = value == "true" ? 'Yes' : 'No'
        setAreYouTotallyFresherValue(value)
        setAreYouTotallyFresherToggleValue(toggleValue)
    }
    const executeTemplateButton = () => {
        props.executeTemplateButton(textareaValue, keyword)
    }
    const setProjectItem = (item: any) => {
        const bindProject = projectValue
        const projectDetails = `/\nProject Name: ${item.ProjectName}/\nDescription: ${item.Description}/\nStart Date: ${item.StartDate}/\nEnd Date: ${item.EndDate}/\nProject URL: ${item.ProjectURL}/\nRole In Project: ${item.RoleInProject}/\nTechnologies Used: ${item.TechnologiesUsed}/\n`
        const setInState = bindProject + projectDetails
        return projectDetails
    }
    const getButtonVisiblety = () => {
        const value = props.templateValue
        switch(value) {
            case 'Profile Title':
                return aboutMeValue && expertiseValue && yearsInNumberValue ? false : true
            case 'Profile Description':
                return aboutMeValue && expertiseValue && yearsInNumberValue ? false : true
            case 'Portfolio Creation':
                return selectProjectValue && projectNameValue && projectDetailsValue ? false : true
            case 'Proposal Writing':
                return aboutMeValue && expertiseValue && skillsValue && yearsInNumberValue && clientRequirementValue && projectValue ? false : true
            case 'Requirement Gathering':
                return clientRequirementValue && myUnderstandingValue && purposedSolutionValue ? false : true
            case 'Instructor':
                return expertiseValue && skillsValue && problemStatementValue ? false : true
            case 'Communication Coach':
                return clientRequirementValue && communicationDetailsValue && myOpinionValue ? false : true
            case 'Price Advisor':
                return clientAvgRateValue && skillsValue && areYouTotallyFresherToggleValue ? false : true
            case 'Estimate':
                return clientProjectTitle && clientProjectSummary && clientProjectObjectives && clientSkillsRequired ? false : true
            case 'Case Studies':
                return caseStudiesDescription ? false : true
            default:
                return true;
        }
    }
    const resetValue = () => {
        setAboutMeValue('')
        setExpertiseValue('')
        setYearsInNumberValue('')
        setSelectProjectValue('')
        setProjectNameValue('')
        setProjectDetailsValue('')
        setClientRequirementValue('')
        setProjectValue('')
        setProject2Value('')
        setProject3Value('')
        setProject4Value('')
        setMyUnderstandingValue('')
        setPurposedSolutionValue('')
        setProblemStatementValue('')
        setCommunicationDetailsValue('')
        setMyOpinionValue('')
        setClientAvgRateValue('')
        setClientProjectTitle('')
        setClientProjectSummary('')
        setClientProjectObjectives('')
        setClientSkillsRequired('')
        setCaseStudiesDescription('')
    }
    return(
        <div className='marketing'>
            <div className="row">
                {/* <div className="col-sm-12 mt-2"><p>Description: Suggest 10 catchy blog post titles for a specific topic</p></div> */}
                <div className="col-sm-12 mt-2">
                    <div id="reverse-radio" className="mb-3">
                        {indianlanguagesData.map((item, i) => 
                            <Form.Check
                                key={i}
                                reverse
                                onChange={onChangeRadio}
                                label={item['name']}
                                checked={selectedValue === item['name']}
                                name="group1"
                                value={item['name']}
                                type='radio'
                                id={`reverse-radio-${i}`}
                            />
                        )}
                    </div>
                </div>
                {radioValue === "Other languages" && <div className="col-sm-4 mt-2">
                    <Form.Label htmlFor="languages">Languages:</Form.Label>
                    <Form.Select id="languages" 
                        value={languagesValue}
                        onChange={onChangeLanguages}
                    >
                        {languagesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>  
                </div>}
                <div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    <Form.Label htmlFor="voicetones">Voice Tones:</Form.Label>
                    <Form.Select 
                        id="voicetones" 
                        aria-label="Default"
                        value={voicetonesValue}
                        onChange={onChangeVoicetones}
                    >
                        <option>Default</option>
                        {voicetonesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>  
                </div>
                <div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    <Form.Label htmlFor="writingStyles">Writing Styles:</Form.Label>
                    <Form.Select 
                        id="writingStyles" 
                        aria-label="Default"
                        value={writingStylesValue}
                        onChange={onChangeWritingStyles}
                    >
                        <option>Default</option>
                        {writingStylesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>
                </div>
                {props.templateValue === 'Profile Title' ||
                props.templateValue === 'Profile Description' || 
                props.templateValue === "Proposal Writing" ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="aboutMe">About Me:</Form.Label>
                    <Form.Control 
                        id="aboutMe"
                        value={aboutMeValue}
                        readOnly
                        as='textarea' 
                        rows={3}
                        onChange={onChangeAboutMe}
                    />
                </div> : <></>}
                {props.templateValue === 'Profile Title' ||
                props.templateValue === 'Profile Description' ||
                props.templateValue === "Proposal Writing" || 
                props.templateValue === 'Instructor' ? <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="expertise">Expertise</Form.Label>
                    <Form.Control
                        value={expertiseValue}
                        type="text"
                        readOnly
                        id="expertise"
                        aria-describedby="Expertise"
                        onChange={onChangeExpertise} 
                    />
                </div> : <></>}
                {props.templateValue === 'Price Advisor' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="clientAvgRate">Client Avg Rate</Form.Label>
                    <Form.Control
                        value={clientAvgRateValue}
                        type="text"
                        id="clientAvgRate"
                        aria-describedby="Client Avg Rate"
                        onChange={onChangeClientAvgRate} 
                    />
                </div>}
                {props.templateValue === "Proposal Writing" || 
                props.templateValue === 'Instructor' || 
                props.templateValue === 'Price Advisor' ? <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="Skills">{props.templateValue === 'Price Advisor' ? 'My Skills' : 'Skills'}</Form.Label>
                    <Form.Control
                        value={skillsValue}
                        type="text"
                        readOnly
                        id="Skills"
                        aria-describedby="Skills"
                        onChange={onChangeSkills} 
                    />
                </div> : <></>}
                {props.templateValue === 'Price Advisor' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="AreyoutotallyFresher">Are you totally Fresher?</Form.Label>
                    {/* <Form.Control
                        value={areYouTotallyFresherValue}
                        type="text"
                        id="AreyoutotallyFresher"
                        aria-describedby="Are you totally Fresher?"
                    /> */}
                    <Form.Check // prettier-ignore
                        type="switch"
                        value={areYouTotallyFresherValue}
                        id="AreyoutotallyFresher"
                        label="Are you totally Fresher?"
                        onChange={onChangeAreYouTotallyFresher} 
                    />
                </div>}
                {props.templateValue === 'Profile Title' ||
                props.templateValue === 'Profile Description' || 
                props.templateValue === "Proposal Writing" ?  <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="yearsInNumber">Years In Number </Form.Label>
                    <Form.Control
                        value={yearsInNumberValue}
                        type="text"
                        readOnly
                        id="yearsInNumber"
                        aria-describedby="Years In Number"
                        onChange={onChangeYearsInNumber} 
                    />
                </div> : <></>}
                {props.templateValue === 'Portfolio Creation'  ? <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="selectProjectValue">Select Project:</Form.Label>
                    <Form.Select 
                        id="selectProjectValue" 
                        aria-label="Default"
                        value={selectProjectValue}
                        onChange={onChangeSelectProjectValue}
                    >
                        <option>Default</option>
                        {selectProject.map((item: any, i) => <option key={i} value={item.ProjectID}>{item.ProjectName}</option>)}
                    </Form.Select>
                </div> : <></>}
                {props.templateValue === 'Portfolio Creation' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="ProjectName">Project Name</Form.Label>
                    <Form.Control
                        value={projectNameValue}
                        type="text"
                        readOnly
                        id="ProjectName"
                        aria-describedby="Project Name "
                        onChange={onChangeProjectName} 
                    />
                </div>}
                {props.templateValue === "Proposal Writing" || 
                props.templateValue === 'Requirement Gathering' || 
                props.templateValue === 'Communication Coach' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="ClientRequirement">Client Requirement</Form.Label>
                    <Form.Control
                        value={clientRequirementValue}
                        as='textarea' 
                        rows={3}
                        id="ClientRequirement"
                        aria-describedby="Client Requirement"
                        onChange={onChangeClientRequirement} 
                    />
                </div> : <></>}
                {props.templateValue === "Proposal Writing" && (
                    selectProject && selectProject.map((item, i) => (
                        <div className="col-sm-6  mt-2" key={i}>
                            <Form.Label htmlFor="ProjectName">Project {i}</Form.Label>
                            <Form.Control
                                value={setProjectItem(item)}
                                as='textarea' 
                                readOnly
                                rows={3}
                                id={'Project-'+i}
                                aria-describedby={'Project-'+i}
                            />
                        </div>
                    ))
                )}
                {/* {props.templateValue === "Proposal Writing" && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="ProjectName">Project 1</Form.Label>
                    <Form.Control
                        value={projectValue}
                        type="text"
                        id="Project1"
                        aria-describedby="Project 1 "
                        onChange={onChangeProject1} 
                    />
                </div>}
                {props.templateValue === "Proposal Writing" && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="Project2">Project 2</Form.Label>
                    <Form.Control
                        value={project2Value}
                        type="text"
                        id="Project2"
                        aria-describedby="Project 2 "
                        onChange={onChangeProject2} 
                    />
                </div>}
                {props.templateValue === "Proposal Writing" && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="Project3">Project 3</Form.Label>
                    <Form.Control
                        value={project3Value}
                        type="text"
                        id="Project3"
                        aria-describedby="Project 3 "
                        onChange={onChangeProject3} 
                    />
                </div>}
                {props.templateValue === "Proposal Writing" && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="Project4">Project 4</Form.Label>
                    <Form.Control
                        value={project4Value}
                        type="text"
                        id="Project4"
                        aria-describedby="Project 4 "
                        onChange={onChangeProject4} 
                    />
                </div>} */}
                {props.templateValue === 'Portfolio Creation' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="ProjectDetails">Project Details</Form.Label>
                    <Form.Control
                        value={projectDetailsValue}
                        as='textarea' 
                        rows={3}
                        readOnly
                        id="ProjectDetails"
                        aria-describedby="Project Details"
                        onChange={onChangeProjectDetails} 
                    />
                </div>}
                {props.templateValue === 'Requirement Gathering' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="MyUnderstanding">My Understanding</Form.Label>
                    <Form.Control
                        value={myUnderstandingValue}
                        as='textarea' 
                        rows={3}
                        id="MyUnderstanding"
                        aria-describedby="My Understanding"
                        onChange={onChangeMyUnderstanding} 
                    />
                </div>}
                {props.templateValue === 'Requirement Gathering' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="PurposedSolution">Purposed Solution</Form.Label>
                    <Form.Control
                        value={purposedSolutionValue}
                        as='textarea' 
                        rows={3}
                        id="PurposedSolution"
                        aria-describedby="Purposed Solution"
                        onChange={onChnagePurposedSolution} 
                    />
                </div>}
                {props.templateValue === 'Instructor' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="ProblemStatement">Problem Statement</Form.Label>
                    <Form.Control
                        value={problemStatementValue}
                        as='textarea' 
                        rows={3}
                        id="ProblemStatement"
                        aria-describedby="Problem Statement"
                        onChange={onChnageProblemStatement} 
                    />
                </div>}
                {props.templateValue === 'Communication Coach' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="CommunicationDetails">Communication Details</Form.Label>
                    <Form.Control
                        value={communicationDetailsValue}
                        as='textarea' 
                        rows={3}
                        id="CommunicationDetails"
                        aria-describedby="Communication Details"
                        onChange={onChnageCommunicationDetails} 
                    />
                </div>}
                {props.templateValue === 'Communication Coach' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="MyOpinion">My Opinion</Form.Label>
                    <Form.Control
                        value={myOpinionValue}
                        as='textarea' 
                        rows={3}
                        id="MyOpinion"
                        aria-describedby="My Opinion"
                        onChange={onChnageMyOpinion} 
                    />
                </div>}
                {props.templateValue === 'Estimate' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="projectTitle">Project Title</Form.Label>
                    <Form.Control
                        value={clientProjectTitle}
                        type='text'
                        id="projectTitle"
                        aria-describedby="Project Title"
                        onChange={onChnageClientProjectTitle} 
                    />
                </div>}
                {props.templateValue === 'Estimate' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="projectSummary">Project Summary</Form.Label>
                    <Form.Control
                        value={clientProjectSummary}
                        as='textarea' 
                        rows={3}
                        id="projectSummary"
                        aria-describedby="Project Summary"
                        onChange={onChnageClientProjectSummary} 
                    />
                </div>}
                {props.templateValue === 'Estimate' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="projectObjectives">Project Objectives</Form.Label>
                    <Form.Control
                        value={clientProjectObjectives}
                        as='textarea' 
                        rows={3}
                        id="projectObjectives"
                        aria-describedby="Project Objectives"
                        onChange={onChnageClientProjectObjectives} 
                    />
                </div>}
                {props.templateValue === 'Estimate' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="skillsRequired">Skills Required</Form.Label>
                    <Form.Control
                        value={clientSkillsRequired}
                        as='textarea' 
                        rows={3}
                        id="skillsRequired"
                        aria-describedby="Skills Required"
                        onChange={onChnageClientSkillsRequired} 
                    />
                </div>}
                {props.templateValue === 'Case Studies' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="caseStudiesDescription">Description</Form.Label>
                    <Form.Control
                        value={caseStudiesDescription}
                        as='textarea' 
                        rows={3}
                        id="caseStudiesDescription"
                        aria-describedby="Description"
                        onChange={onChnageCaseStudiesDescription} 
                    />
                </div>}
            </div>
            <div className="row">
                <div className="col-sm-12  mt-2">
                    <Button id="executeTemplate" 
                        onClick={executeTemplateButton} 
                        disabled={getButtonVisiblety()}
                    >Execute Template</Button>
                </div>
            </div>
        </div>
    )   
}

export default FreelancerTemplate;

const indianLanguages: any = {
    "Assamese":"Assamese",
    "Bengali":"Bengali",
    "Bodo":"Bodo",
    "Dogri":"Dogri",
    "English": "English",
    "Gujarati":"Gujarati",
    "Hindi":"Hindi",
    "Kannada":"Kannada",
    "Kashmiri":"Kashmiri",
    "Konkani":"Konkani",
    "Maithili":"Maithili",
    "Malayalam":"Malayalam",
    "Marathi":"Marathi",
    "Meitei":"Meitei",
    "Nepali":"Nepali",
    "Odia":"Odia",
    "Punjabi":"Punjabi",
    "Sanskrit":"Sanskrit",
    "Santali":"Santali",
    "Sindhi":"Sindhi",
    "Tamil":"Tamil",
    "Telugu":"Telugu",
    "Urdu":"Urdu",
    "Other languages": "Other languages"
}

const languages: any = {
    "arabic": "Arabic",
    "bulgarian": "Bulgarian",
    "burmese": "Burmese",
    "czech": "Czech",
    "danish": "Danish",
    "dutch": "Dutch",
    "french": "French",
    "german": "German",
    "greek": "Greek",
    "hungarian": "Hungarian",
    "indonesian": "Indonesian",
    "italian": "Italian",
    "japanese": "Japanese",
    "javanese": "Javanese",
    "korean": "Korean",
    "mandarin chinese": "Mandarin Chinese",
    "polish": "Polish",
    "portuguese": "Portuguese",
    "romanian": "Romanian",
    "russian": "Russian",
    "spanish": "Spanish",
    "swedish": "Swedish",
    "thai": "Thai",
    "turkish": "Turkish",
    "ukranian": "Ukranian",
    "urdu": "Urdu",
    "vietnamese": "Vietnamese",
    "wu chinese": "Wu Chinese",
    "yue chinese": "Yue Chinese"
}

const voicetones: any = {
    "authoritative": "Authoritative",
    "caring": "Caring",
    "casual": "Casual",
    "cheerful": "Cheerful",
    "coarse": "Coarse",
    "conservative": "Conservative",
    "conversational": "Conversational",
    "creative": "Creative",
    "dry": "Dry",
    "edgy": "Edgy",
    "enthusiastic": "Enthusiastic",
    "expository": "Expository",
    "formal": "Formal",
    "frank": "Frank",
    "friendly": "Friendly",
    "fun": "Fun",
    "funny": "Funny",
    "humorous": "Humorous",
    "informative": "Informative",
    "irreverent": "Irreverent",
    "journalistic": "Journalistic",
    "matteroffact": "Matter of fact",
    "nostalgic": "Nostalgic",
    "objective": "Objective",
    "passionate": "Passionate",
    "poetic": "Poetic",
    "playful": "Playful",
    "professional": "Professional",
    "provocative": "Provocative",
    "quirky": "Quirky",
    "respectful": "Respectful",
    "romantic": "Romantic",
    "sarcastic": "Sarcastic",
    "serious": "Serious",
    "smart": "Smart",
    "snarky": "Snarky",
    "subjective": "Subjective",
    "sympathetic": "Sympathetic",
    "trendy": "Trendy",
    "trustworthy": "Trustworthy",
    "unapologetic": "Unapologetic",
    "upbeat": "Upbeat",
    "witty": "Witty"
}
const writingStyles: any = {
    "academic": "Academic",
    "analytical": "Analytical",
    "argumentative": "Argumentative",
    "conversational": "Conversational",
    "creative": "Creative",
    "critical": "Critical",
    "descriptive": "Descriptive",
    "epigrammatic": "Epigrammatic",
    "epistolary": "Epistolary",
    "expository": "Expository",
    "informative": "Informative",
    "instructive": "Instructive",
    "journalistic": "Journalistic",
    "metaphorical": "Metaphorical",
    "narrative": "Narrative",
    "persuasive": "Persuasive",
    "poetic": "Poetic",
    "satirical": "Satirical",
    "technical": "Technical"
}