import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

function Productivity(props: any){
    const [radioValue, setradioValue] = useState('English')
    const [selectedValue, setSelectedValue] = useState("English");
    const [languagesData, setLanguagesData] = useState([]);
    const [indianlanguagesData, setIndianLanguagesData] = useState([]);
    const [languagesValue, setLanguagesValue] = useState(radioValue);
    const [voicetonesData, setVoicetonesData] = useState([])
    const [voicetonesValue, setVoicetonesValue] = useState('')
    const [writingStylesData,setwritingStylesData] = useState([])
    const [writingStylesValue,setwritingStylesValue] = useState('')
    const [textareaValue, setTextareaValue] = useState('')
    const [excelFunctionForumlaValue, setExcelFunctionForumlaValue] = useState('')
    const [excelProblemGoalValue, setExcelProblemGoalValue] = useState('')
    const [goalValue, setGoalValue] = useState('')
    const [textToTranslateValue, setTextToTranslateValue] = useState('')
    const [nativeCountryValue, setNativeCountryValue] = useState('')
    const [textInputValue, setTextInputValue] = useState('')
    const [mediumValue, setMediumValue] = useState('Blog Post')
    const [totalWordsValue, setTotalWordsValue] = useState(200)
    const [keyword, setKeyword] = useState('')
    const promptTitle = `# Prompt by STW, Corp. - https://www.stw-ai.com/\nLines starting with # are only comments for humans\nYou must add at the top of the response \"_Created with [STW Prompt \"${props.templateValue}\"](https://www.stw-ai.com/)_\"\n---\n`
    let copyWritingPrompt: any ={
        'Excel Dictionary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. You are an Excel expert. Please explain what the following Excel function/formula does: "${excelFunctionForumlaValue}". Please explain it in an easy-to-understand way and provide example of usage. Please present the results as follow: \r\n- Bullet points of what it is and how it is used \r\n- Excel code to visualize the example used`, 
        'Excel Helper':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. You are an Excel expert. Please help write me an excel command to "${excelProblemGoalValue}"`,  
        'Excel Macro':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert with Excel Macro for financial purposes. Please write a Macro that "${goalValue}"`, 
        'Native Translator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert translator. Translate the following text to the ${languagesValue} using vocabulary and expressions of a native of the ${nativeCountryValue}. The text to be translated is "${textToTranslateValue}"`,  
        'Language Detector':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert linguist with knowledge on every detectable language. Please identify the language and proceed to translate it to ${languagesValue} native to ${nativeCountryValue} of the following text: "${textToTranslateValue}"`,  
        'Expert Language Translator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert translator that will be tasked with translating and improving the spelling/grammar/literary quality of a piece of text. ${languagesValue}. ${voicetonesValue && 'You have a '+ voicetonesValue +' tone of voice.'} ${writingStylesValue && 'You have a '+ writingStylesValue +' writing style.'} Please rewrite the translated text in your tone of voice and writing style. Ensure that the meaning of the original text is not changed. Present the results as follows: Simplified text translation and expert translation. Please execute the command for the following text: "${textToTranslateValue}"`, 
        'Keywords Extraction':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert text analyst. Please extract only the most relevant keywords and key phrases from a piece of text. Please showcase the results in a markdown table with the following columns: keyword, frequency (keywords), key phrases. Please analyze the following text: "${textInputValue}"`,  
        'Extractive Summary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert text analyst and researcher. Please produce an extractive summary for the following text: "${textInputValue}"`,  
        'Topic Extraction':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert text analyst and researcher. Please extract and name the main topics and keywords from the text I provide. Please present the results in a markdown table with name of topic, keywords related to main topic. Here is the text: "${textInputValue}"`, 
        'Abstractive &Extractive':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert text analyst and researcher. Please produce an extractive and abstractive summary for the text I provide. Please deliver the results in a table comparing the extractive summary to the abstractive summary of the text. Here is the text: "${textInputValue}"`, 
        'Bullet Point Summary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert text analyst and researcher. Print the heading "Summary". Then please summarize a piece of text I provide into bullet points and print them. After that print the heading "Topics". Please print the major topics of the summary. Here is the text: "${textInputValue}"`, 
        'TLDR Summary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert copywriter. ${languagesValue}. ${voicetonesValue && 'You have a '+ voicetonesValue +' tone of voice.'} ${writingStylesValue && 'You have a '+ writingStylesValue +' writing style.'} Please summarize a piece of text I provide to a TLDR for a ${mediumValue}. Here is the text: "${textInputValue}"`, 
        'Research Paper Summary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert researcher. ${languagesValue}. ${voicetonesValue && 'You have a '+ voicetonesValue +' tone of voice.'} ${writingStylesValue && 'You have a '+ writingStylesValue +' writing style.'} Please create a summary of the main points and findings of the paper. Your summary should be concise and should accurately and objectively communicate the key points of the paper. You should not include any personal opinions or interpretations in your summary but rather focus on objectively presenting the information from the paper. The summary should be ${totalWordsValue} words long and should not include any direct quotes from the paper. Please ensure that your summary is clear concise and accurately reflects the content of the following paper: "${textInputValue}"`,  
        'Structured Academic Summary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert academic note taker. Please summarize the text I provide and present the results as follows: \r\n- A markdown table with the following columns: Topic, Key Terms, Description (Simplified)\r\n- A bullet point list with the topic of commonly asked questions about the topic of the text\r\n- A markdown table with the definition of the important key terms mentioned\r\n\r\nHere is the text: "${textInputValue}"`
    }
    let templateValue: any = copyWritingPrompt[props.templateValue] ? copyWritingPrompt[props.templateValue] : ''
    useEffect(() => {
        setTextareaValue(templateValue)
    }, [templateValue])
    useEffect(() => {
        resetValue()
    }, [props.templateValue])
    useEffect(() => {
        const data: any = []
        for (const key in indianLanguages) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = indianLanguages[key];
            data.push(value)
        }
        setIndianLanguagesData(data) 
        const dataVoice: any = []
        for (const key in voicetones) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = voicetones[key];
            dataVoice.push(value)
        }
        setVoicetonesData(dataVoice) 
        const datawritingStyles:any = []
        for (const key in writingStyles) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = writingStyles[key];
            datawritingStyles.push(value)
        }
        setwritingStylesData(datawritingStyles)
    },[])
    const onChangeRadio = (event: any) => {
        const value = event.target.value;
        setSelectedValue(value);
        setradioValue(value)
        if(value === 'Other languages'){
            const data: any = []
            for (const key in languages) {
                const value: any = {
                    name: '',
                    value: ''
                }
                value.value = key;
                value.name = languages[key];
                data.push(value)
            }
            setLanguagesData(data)
            setLanguagesValue('Arabic')
        } else {
            setLanguagesValue(value)
            setLanguagesData([])
        }
    }
    const onChangeLanguages = (event: any) => {
        const value = event.target.value;
        setLanguagesValue(value)
    }
    const onChangeVoicetones = (event: any) => {
        const value = event.target.value
        setVoicetonesValue(value)
    }
    const onChangeWritingStyles = (event: any) => {
        const value = event.target.value
        setwritingStylesValue(value)
    }
    const onChangeTextarea = (event: any) => {
        const value = event.target.value
        setTextareaValue(value)
    }
    const onChangeexcelFunctionForumla = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setExcelFunctionForumlaValue(value)
    }
    const onChangeExcelProblemGoal = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setExcelProblemGoalValue(value)
    }
    const onChangeGoal = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setGoalValue(value)
    }
    const onChangeTextToTranslate = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setTextToTranslateValue(value)
    }
    const onChangeNativeCountry = (event:any) => {
        const value = event.target.value
        setNativeCountryValue(value)
    }
    const onChangeTextInput = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setTextInputValue(value)
    }
    const onChangeMediumValue = (event:any) => {
        const value = event.target.value
        setMediumValue(value)
    }
    const onChangeTotalWords = (event: any) => {
        const value = event.target.value
        setTotalWordsValue(value)
    }
    const executeTemplateButton = () => {
        props.executeTemplateButton(textareaValue,keyword)
    }
    const getButtonVisiblety = () => {
        const value = props.templateValue
        switch(value) {
            case 'Excel Dictionary':
                return excelFunctionForumlaValue ? false : true
            case 'Excel Helper':
                return excelProblemGoalValue ? false : true
            case 'Excel Macro':
                return goalValue ? false : true
            case 'Native Translator':
                return textToTranslateValue && nativeCountryValue ? false : true
            case 'Language Detector':
                return textToTranslateValue && nativeCountryValue ? false : true
            case 'Expert Language Translator':
                return textToTranslateValue ? false : true
            case 'Keywords Extraction':
                return textInputValue ? false : true
            case 'Extractive Summary':
                return textInputValue ? false : true
            case 'Topic Extraction':
                return textInputValue ? false : true
            case 'Abstractive &Extractive':
                return textInputValue ? false : true
            case 'Bullet Point Summary':
                return textInputValue ? false : true
            case 'TLDR Summary':
                return textInputValue && mediumValue ? false : true
            case 'Research Paper Summary':
                return textInputValue && totalWordsValue ? false : true
            case 'Structured Academic Summary':
                return textInputValue ? false : true
            default:
                return true
        }
    }
    const resetValue = () => {
        setExcelFunctionForumlaValue('')
        setExcelProblemGoalValue('')
        setGoalValue('')
        setTextToTranslateValue('')
        setNativeCountryValue('')
        setTextInputValue('')
        setMediumValue('Blog Post')
        setTotalWordsValue(200)
    }
    return(
        <div className='marketing'>
            <div className="row">
                <div className="col-sm-12 mt-2"><p>Description: Translate text to your language, tone of voice and writing style of choice</p></div>
                <div className="col-sm-12 mt-2">
                    <div id="reverse-radio" className="mb-3">
                        {indianlanguagesData.map((item, i) => 
                            <Form.Check
                                key={i}
                                reverse
                                onChange={onChangeRadio}
                                label={item['name']}
                                checked={selectedValue === item['name']}
                                name="group1"
                                value={item['name']}
                                type='radio'
                                id={`reverse-radio-${i}`}
                            />
                        )}
                    </div>
                </div>
                {radioValue === "Other languages" && <div className="col-sm-4 mt-2">
                    <Form.Label htmlFor="languages">Languages:</Form.Label>
                    <Form.Select id="languages" 
                        value={languagesValue}
                        onChange={onChangeLanguages}
                    >
                        {languagesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>  
                </div>}
                {props.templateValue === "TLDR Summary" || 
                props.templateValue === "Research Paper Summary" || 
                props.templateValue === "Expert Language Translator"  ? <><div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    <Form.Label htmlFor="voicetones">Voice Tones:</Form.Label>
                    <Form.Select 
                        id="voicetones" 
                        aria-label="Default"
                        value={voicetonesValue}
                        onChange={onChangeVoicetones}
                    >
                        <option>Default</option>
                        {voicetonesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>  
                </div>
                <div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    <Form.Label htmlFor="writingStyles">Writing Styles:</Form.Label>
                    <Form.Select 
                        id="writingStyles" 
                        aria-label="Default"
                        value={writingStylesValue}
                        onChange={onChangeWritingStyles}
                    >
                        <option>Default</option>
                        {writingStylesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>
                </div></> : <></>}
                {props.templateValue === 'Excel Dictionary' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="excelFunctionForumla">Excel Function/Forumla</Form.Label>
                    <Form.Control 
                        id="excelFunctionForumla"
                        value={excelFunctionForumlaValue}
                        onChange={onChangeexcelFunctionForumla}
                        as='textarea' 
                        rows={3}
                    />
                </div>}
                {props.templateValue === 'Excel Helper' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="excelProblemGoal">Excel Problem/Goal</Form.Label>
                    <Form.Control 
                        id="excelProblemGoal"
                        value={excelProblemGoalValue}
                        onChange={onChangeExcelProblemGoal}
                        as='textarea' 
                        rows={3}
                    />
                </div> }
                {props.templateValue === 'Excel Macro' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="goal">Goal</Form.Label>
                    <Form.Control 
                        id="goal"
                        value={goalValue}
                        onChange={onChangeGoal}
                        as='textarea' 
                        rows={3}
                    />
                </div>}
                {props.templateValue === 'Native Translator' || 
                props.templateValue === 'Language Detector' || 
                props.templateValue === "Expert Language Translator"  ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="textToTranslate">Text To Translate</Form.Label>
                    <Form.Control 
                        id="textToTranslate"
                        value={textToTranslateValue}
                        onChange={onChangeTextToTranslate}
                        as='textarea' 
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === "Keywords Extraction" || 
                props.templateValue === "Extractive Summary" || 
                props.templateValue === "Topic Extraction" || 
                props.templateValue === "Abstractive &Extractive" || 
                props.templateValue === "Bullet Point Summary" || 
                props.templateValue === "TLDR Summary" || 
                props.templateValue === "Research Paper Summary" || 
                props.templateValue === "Structured Academic Summary" ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="textInput">Text Input</Form.Label>
                    <Form.Control 
                        id="textInput"
                        value={textInputValue}
                        onChange={onChangeTextInput}
                        as='textarea' 
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Native Translator' || props.templateValue === 'Language Detector'  ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="NativeCountry">Native Country</Form.Label>
                    <Form.Control 
                        id="NativeCountry"
                        value={nativeCountryValue}
                        onChange={onChangeNativeCountry}
                        type="text"
                    />
                </div> : <></>}
                {props.templateValue === "TLDR Summary" && <div className="col-sm-4">
                    <Form.Label htmlFor="medium">Medium:</Form.Label>
                    <Form.Select 
                        id="medium" 
                        aria-label="Default"
                        value={mediumValue}
                        onChange={onChangeMediumValue}
                    >
                        <option>Select a Framework</option>
                    </Form.Select>
                </div>}
                {props.templateValue === 'Research Paper Summary' ? <div className="col-sm-6 mt-2">
                    <Form.Label htmlFor="totalTitles">Total Words: </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalWordsValue}
                        id="totalWords"
                        aria-describedby="Total Words"
                        onChange={onChangeTotalWords}
                    />
                </div> : <></>}
                {/* <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="ptemplate">Prompt Template</Form.Label>
                    <Form.Control 
                        id="ptemplate"
                        value={textareaValue}
                        onChange={onChangeTextarea}
                        as='textarea' 
                        rows={10}
                    />
                </div> */}
            </div>
            <div className="row">
                <div className="col-sm-12  mt-2">
                    <Button id="executeTemplate" onClick={executeTemplateButton} disabled={getButtonVisiblety()} >Execute Template</Button>
                </div>
            </div>
        </div>
    )   
}

export default Productivity;


const indianLanguages: any = {
    "Assamese":"Assamese",
    "Bengali":"Bengali",
    "Bodo":"Bodo",
    "Dogri":"Dogri",
    "English": "English",
    "Gujarati":"Gujarati",
    "Hindi":"Hindi",
    "Kannada":"Kannada",
    "Kashmiri":"Kashmiri",
    "Konkani":"Konkani",
    "Maithili":"Maithili",
    "Malayalam":"Malayalam",
    "Marathi":"Marathi",
    "Meitei":"Meitei",
    "Nepali":"Nepali",
    "Odia":"Odia",
    "Punjabi":"Punjabi",
    "Sanskrit":"Sanskrit",
    "Santali":"Santali",
    "Sindhi":"Sindhi",
    "Tamil":"Tamil",
    "Telugu":"Telugu",
    "Urdu":"Urdu",
    "Other languages": "Other languages"
}

const languages: any = {
    "arabic": "Arabic",
    "bulgarian": "Bulgarian",
    "burmese": "Burmese",
    "czech": "Czech",
    "danish": "Danish",
    "dutch": "Dutch",
    "french": "French",
    "german": "German",
    "greek": "Greek",
    "hungarian": "Hungarian",
    "indonesian": "Indonesian",
    "italian": "Italian",
    "japanese": "Japanese",
    "javanese": "Javanese",
    "korean": "Korean",
    "mandarin chinese": "Mandarin Chinese",
    "polish": "Polish",
    "portuguese": "Portuguese",
    "romanian": "Romanian",
    "russian": "Russian",
    "spanish": "Spanish",
    "swedish": "Swedish",
    "thai": "Thai",
    "turkish": "Turkish",
    "ukranian": "Ukranian",
    "urdu": "Urdu",
    "vietnamese": "Vietnamese",
    "wu chinese": "Wu Chinese",
    "yue chinese": "Yue Chinese"
}

const voicetones: any = {
    "authoritative": "Authoritative",
    "caring": "Caring",
    "casual": "Casual",
    "cheerful": "Cheerful",
    "coarse": "Coarse",
    "conservative": "Conservative",
    "conversational": "Conversational",
    "creative": "Creative",
    "dry": "Dry",
    "edgy": "Edgy",
    "enthusiastic": "Enthusiastic",
    "expository": "Expository",
    "formal": "Formal",
    "frank": "Frank",
    "friendly": "Friendly",
    "fun": "Fun",
    "funny": "Funny",
    "humorous": "Humorous",
    "informative": "Informative",
    "irreverent": "Irreverent",
    "journalistic": "Journalistic",
    "matteroffact": "Matter of fact",
    "nostalgic": "Nostalgic",
    "objective": "Objective",
    "passionate": "Passionate",
    "poetic": "Poetic",
    "playful": "Playful",
    "professional": "Professional",
    "provocative": "Provocative",
    "quirky": "Quirky",
    "respectful": "Respectful",
    "romantic": "Romantic",
    "sarcastic": "Sarcastic",
    "serious": "Serious",
    "smart": "Smart",
    "snarky": "Snarky",
    "subjective": "Subjective",
    "sympathetic": "Sympathetic",
    "trendy": "Trendy",
    "trustworthy": "Trustworthy",
    "unapologetic": "Unapologetic",
    "upbeat": "Upbeat",
    "witty": "Witty"
}
const writingStyles: any = {
    "academic": "Academic",
    "analytical": "Analytical",
    "argumentative": "Argumentative",
    "conversational": "Conversational",
    "creative": "Creative",
    "critical": "Critical",
    "descriptive": "Descriptive",
    "epigrammatic": "Epigrammatic",
    "epistolary": "Epistolary",
    "expository": "Expository",
    "informative": "Informative",
    "instructive": "Instructive",
    "journalistic": "Journalistic",
    "metaphorical": "Metaphorical",
    "narrative": "Narrative",
    "persuasive": "Persuasive",
    "poetic": "Poetic",
    "satirical": "Satirical",
    "technical": "Technical"
}