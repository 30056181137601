import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';

function Copywriting(props: any){
    const [radioValue, setradioValue] = useState('English')
    const [selectedValue, setSelectedValue] = useState("English");
    const [languagesData, setLanguagesData] = useState([]);
    const [indianlanguagesData, setIndianLanguagesData] = useState([]);
    const [languagesValue, setLanguagesValue] = useState(radioValue);
    const [voicetonesData, setVoicetonesData] = useState([])
    const [voicetonesValue, setVoicetonesValue] = useState('')
    const [writingStylesData,setwritingStylesData] = useState([])
    const [writingStylesValue,setwritingStylesValue] = useState('')
    const [totalTitlesValue, setTotalTitlesValue] = useState(10)
    const [frameworkValue, setFrameworkValue] = useState("AIDA (Attention Interest Desire Action)")
    const [keywordsValue, setKeywordsValue] = useState('')
    const [topicValue, setTopicValue] = useState('')
    const [blogpostOutlineValue, setBlogPostOutlineValue] = useState('')
    const [titleValue, setTitleValue] = useState('')
    const [bpTitleValue, setBpTitleValue] = useState('')
    const [totalWordsValue, setTotalWordsValue] = useState(200)
    const [totalDescriptionsValue, setTotalDescriptionsValue] = useState(10)
    const [totalHeadlines, setTotalHeadlines] = useState(20)
    const [textareaValue, setTextareaValue] = useState('')
    const [personaValue, setPersonaValue] = useState('a top selling author of horror, supernatural fiction, suspense, crime, science fiction, and fantasy novels')
    const [bookDetailsValue, setBookDetailsValue] = useState('The book teaches the readers how to get better at marketing ...')
    const [plotSummaryValue, setPlotSummaryValue] = useState('')
    const [chapterSummaryValue, setChapterSummaryValue] = useState('')
    const [contentValue, setContentValue] = useState('')
    const [articleTitleValue, setArticleTitleValue] = useState('')
    const [mainKeywordValue, setMainKeywordValue] = useState('')
    const [totalKeywordsValue, setTotalKeywordsValue] = useState(10)
    const [totalQuestionsValue, setTotalQuestionsValue] = useState(10)
    const [articlesPerWeekValue, setArticlesPerWeekValue] = useState(10)
    const [numberofmonthsValue, setNumberofmonthsValue] = useState(10)
    const [numberofFAQsValue, setNumberofFAQsValue] = useState(10)
    const [industryValue, setIndustryValue] = useState('')
    const [totalIdeasValue, setTotalIdeasValue] = useState(10)
    const [educationLevelValue, setEducationLevelValue] = useState('Primary')
    const [courseTopicValue, setCourseTopicValue] = useState('')
    const [totalSessionsValue, setTotalSessionsValue] = useState(10)
    const [totalModulesValue, setTotalModulesValue] = useState(10)
    const [courseLessonValue, setCourseLessonValue] = useState('')
    const [totalAssignmentsValue, setTotalAssignmentsValue] = useState(10)
    const [emailValue, setEmailValue] = useState('')
    const [productServiceValue, setProductServiceValue] = useState('')
    const [totalSubjectLinesValue, setTotalSubjectLinesValue] = useState(5)
    const [useEmojisValue, setUseEmojisValue] = useState('text and emojis')
    const [customerNameValue, setCustomerNameValue] = useState('')
    const [customerCompanyValue, setCustomerCompanyValue] = useState('')
    const [outreachContextValue, setOutreachContextValue] = useState('')
    const [yourCompanyValue, setYourCompanyValue] = useState('')
    const [yourNameValue, setYourNameValue] = useState('')
    const [productServiceNameValue, setProductServiceNameValue] = useState('')
    const [callToActionValue, setCallToActionValue] = useState('')
    const [productServiceDescriptionValue, setProductServiceDescriptionValue] = useState('')
    const [yourRoleValue, setYourRoleValue] = useState('Customer Service Agent')
    const [referralRewardValue, setReferralRewardValue] = useState('There is a monetary reward for the referal')
    const [emailsPerWeekValue, setEmailsPerWeekValue] = useState(10)
    const [totalMonthsValue, setTotalMonthsValue] = useState(10)
    const [yourSaaSToolValue, setYourSaaSToolValue] = useState('')
    const [competitorsValue, setCompetitorsValue] = useState('')
    const [keyword, setKeyword] = useState('')
    const promptTitle = `# Prompt by STW, Corp. - https://www.stw-ai.com/\nLines starting with # are only comments for humans\nYou must add at the top of the response \"_Created with [STW Prompt \"${props.templateValue}\"](https://www.stw-ai.com/)_\"\n---\n`
    
    let copyWritingPrompt: any ={
        "Generate Blog Post Titles": `${promptTitle}Previous instruction should be ignored. You are an expert copywriter who writes catchy titles for blog posts. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Write ${totalTitlesValue} catchy blog post titles with a hook for the topic "${topicValue}". The titles should be written in the ${languagesValue} language. The titles should be less than 60 characters. The titles should include the words from the topic "${topicValue}". Do not use single quotes, double quotes or any other enclosing characters. Do not self reference. Do not explain what you are doing.`,
        "Generate Blog Post Descriptions":`${promptTitle}Previous instruction should be ignored. You are an expert copywriter who writes catchy descriptions for blog posts. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Write ${totalTitlesValue} catchy blog post descriptions with a hook for the blog post titled "${titleValue}". The descriptions should be written in the ${languagesValue} language. The descriptions should be less than 160 characters. The descriptions should include the words from the title "${titleValue}". Do not use single quotes, double quotes or any other enclosing characters. Do not self reference. Do not explain what you are doing.`,
        "Generate Blog Post Outline":`${promptTitle}Previous instruction should be ignored. You are an expert copywriter who creates content outlines. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Create a long form content outline in the ${languagesValue} language for the blog post titled "${bpTitleValue}".  The content outline should include a minimum of "${totalHeadlines}" headings and subheadings. The outline should be extensive and it should cover the entire topic. Create detailed subheadings that are engaging and catchy. Do not write the blog post, please only write the outline of the blog post. Please do not number the headings. Please add a newline space between headings and subheadings. Do not self reference. Do not explain what you are doing.`,
        "Generate Complete Blog Post From Outline":`${promptTitle}Previous instruction should be ignored. You are an expert copywriter who writes detailed and thoughtful blog articles. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} I will give you an outline for an article and I want you to expand in the ${languagesValue} language on each of the subheadings to create a complete article from it. Please intersperse short and long sentences. Utilize uncommon terminology to enhance the originality of the content. Please format the content in a professional format. Do not self reference. Do not explain what you are doing. The blog article outline is - "${blogpostOutlineValue}"`,
        "Generate Complete Blog Post From Topic":`${promptTitle}Previous instruction should be ignored. You are an expert copywriter who writes detailed and thoughtful blog articles. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} I will give you a topic for an article and I want you to create an outline for the topic with a minimum of ${totalHeadlines} headings and subheadings. I then want you to expand in the ${languagesValue} language on each of the individual subheadings in the outline to create a complete article from it. Please intersperse short and long sentences. Utilize uncommon terminology to enhance the originality of the content. Please format the content in a professional format. Do not self reference. Do not explain what you are doing. Send me the outline and then immediately start writing the complete article. The blog article topic is - "${topicValue}".`,
        "Generate Introduction Using Framework":`${promptTitle}Previous instruction should be ignored. You are an expert copywriter who writes detailed and compelling blog articles. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} I want you to write a compelling blog introduction paragraph of around ${totalWordsValue} words on "${topicValue}" in the ${languagesValue} language. Please use the ${frameworkValue} copywriting framework to hook and grab the attention of the blog readers. Please intersperse short and long sentences. Utilize uncommon terminology to enhance the originality of the content. Please format the content in a professional format. Do not self reference. Do not explain what you are doing. I will give you a list of keywords below and it would be great if you can add them into the text wherever appropriate. Please do highlight these keywords in bold in the text using markdown if you have them in the text. Here are the keywords - "${keywordsValue}". Remember that the topic is "${topicValue}"`,
        "Generate Paragraph Of Text":`${promptTitle}Previous instruction should be ignored. You are an expert copywriter who writes detailed and thoughtful blog articles. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} I want you to write around ${totalWordsValue} words on "${topicValue}" in the ${languagesValue} language. I will give you a list of keywords that need to be in the text that you create. Please intersperse short and long sentences. Utilize uncommon terminology to enhance the originality of the content. Please format the content in a professional format. Do not self reference. Do not explain what you are doing. Here are the keywords - "${keywordsValue}". Please highlight these keywords in bold in the text using markdown.`,
        "Plot Generator": `${promptTitle}Previous instruction should be ignored. You are ${personaValue}. Please generate a book title and a plot summary for a top selling book. ${bookDetailsValue}. Do not write the book, please only write the plot summary of the book. Please respond only in the ${languagesValue} language. Do not self reference. Do not explain what you are doing. Please only provide the plot summary and nothing else.`, 
        "Book Title Generator": `${promptTitle}Previous instruction should be ignored. You are ${personaValue}. I will give you the plot summary for a book, and I want you to generate ${totalTitlesValue} catchy top selling titles for it. The titles should only be in the ${languagesValue} language. Do not self reference. Do not explain what you are doing. Please only provide the book titles nothing else. The plot summary is - "${plotSummaryValue}"`, 
        "Book Outline Generator": `${promptTitle}Previous instruction should be ignored. You are ${personaValue}. I will give you the plot summary for a book, and I want you to generate ${totalWordsValue} catchy top selling titles for it. The titles should only be in the ${languagesValue} language. Do not self reference. Do not explain what you are doing. Please only provide the book titles nothing else. The plot summary is - "${plotSummaryValue}"`, 
        "Book Chapter Generator": `${promptTitle}Previous instruction should be ignored. You are ${personaValue}. I will give you the book summary as well as the summary for a single chapter. I want you to write the entire chapter based on the chapter summary. Do not write the entire book, please only generate the text of a single chapter. Please write only in the ${languagesValue} language. Do not self reference. Do not explain what you are doing. The plot summary is "${plotSummaryValue}". The chapter summary is "${chapterSummaryValue}"`,
        'Content Titles Generator': `${promptTitle}Previous instruction should be ignored. You are an expert copywriter who writes catchy titles for articles. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Write ${totalTitlesValue} catchy article titles with a hook for the topic "${topicValue}". The titles should be in the ${languagesValue} language. Do not self reference. Do not explain what you are doing.`, 
        'Content Rewriter (Paste Content)': `${promptTitle}Previous instruction should be ignored. You are an expert copywriter who creates content briefs. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Please write only in the ${languagesValue} language. The article title is "${articleTitleValue}". First print out  "Content Brief for ${mainKeywordValue} " as a heading. Then print a heading "Content Overview". Then print "Title" and write the article title. After this print  "Meta Description". Now generate a meta description for the article title that is less than 160 characters. The description should contain the keyword "${mainKeywordValue}". After this table print out the following "Outline  What is this content about". Generate a content outline for the article "${articleTitleValue}" here. After this print the following "What keywords and topics are recommended or required?" as a heading. Now list down ${totalKeywordsValue}  keywords that are closely related to "${mainKeywordValue}". After this print the following "What key questions do readers have that need to be answered?" as a heading. Now generate ${totalQuestionsValue} questions that the reader may have related to the "${articleTitleValue}" and "${mainKeywordValue}" and print them out. Do not self reference. Do not explain what you are doing.`, 
        'Content Outline Generator': `${promptTitle}Previous instruction should be ignored. You are an expert copywriter who creates content outlines. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Create a long form content outline in the ${languagesValue} language for the article titled "${articleTitleValue}".  The content outline should include a minimum of 20 headings and subheadings. The outline should be extensive and it should cover the entire topic. Create detailed subheadings that are engaging and catchy. Do not write the article, please only write the outline of the article. Do not self reference. Do not explain what you are doing.`, 
        'Monthly Content Calendar': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a copywriter who is an expert in creating content calendars. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Please create a content calendar for ${numberofmonthsValue} months using long tail keywords related to my topic. There should be ${articlesPerWeekValue} blog posts scheduled each week of the month. Every blog post should have a catchy &click-bait title. The table should have actual dates in the future. Please organize each blog post in the table so that it looks like a calendar. My topic is "${topicValue}". Do not self reference. Do not explain what you are doing. Reply back only with the table.`, 
        'FAQ Generator': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a market research expert who is an expert at generating questions for topics. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Please generate ${numberofFAQsValue} most frequently asked questions on the topic "${topicValue}". Do not self reference. Do not explain what you are doing. Reply back only with the table.`, 
        'Article to Listicle': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert copywriter. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Convert the text given below to a listicle section of a blog post. Make sure to present the section starting with a short paragraph of 2 sentences, and ending with a paragraph of 2 sentences. The text to convert to a listicle is - "${contentValue}"`,
        'Course Idea Generator': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert in the ${industryValue} industry and create amazing online courses. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Generate ${totalIdeasValue} course ideas for a ${educationLevelValue}-level course in the ${industryValue} industry. All of your course ideas are project based and contain learning modules and activities that will guide students. Please only generate the ideas for the courses and nothing else.`, 
        'Course Outline Generator': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert course creator and curriculum designer. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Create a syllabus for a ${totalSessionsValue} session ${educationLevelValue}-level course on "${courseTopicValue}". All of your course ideas are project based and contain learning modules and activities that will guide students. Please only generate the syllabus for the course and nothing else.`, 
        'Complete Course Lesson': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert course creator and curriculum designer. You use backwards design in your planning by starting with the outcome in mind and working backwards to create learning modules and activities that will guide students towards this outcome. All of your courses are project based. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Create a course lesson plan for "${courseLessonValue}". The course is on the topic "${courseTopicValue}". The lesson plan should be descriptive with the real world examples and activities. Include discussion questions and ways to expand learning. Please only generate the lesson plan.`, 
        'Course Assignment Generator': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert course creator and curriculum designer. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Create ${totalAssignmentsValue} assignments for a ${educationLevelValue}-level course on "${courseTopicValue}". Please include the list of assignments in a markdown table.`,
        'Professional Email Editor': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert email writer and editor. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Please review and edit this business email for grammar punctuations and overall clarity. Do not change the meaning of the email. Please edit the following email: "${emailValue}".`, 
        'Marketing Email Generator': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a professional email marketer. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Write the best marketing email promoting this product or service: "${productServiceValue} ". Please include the keywords  "${keywordsValue} ". Please include ${totalSubjectLinesValue} email subject lines that use relevant text and emojis and has to be 50 to 60 characters long.`, 
        'Sales Prospecting Email': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a sales manager. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Your name is  "${yourNameValue} " and you are from  "${yourCompanyValue} " that sells  "${productServiceValue} ", write an introductory warm sales email to ${customerNameValue} ${customerCompanyValue}. Please reference the following outreach context in the first part of the email  "${outreachContextValue} "`, 
        'Cold Outreach Email': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a sales manager. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. You are sending out a cold email campaign for  "${productServiceNameValue} " -  "${productServiceDescriptionValue} ". The goal of the email is to persuade new prospects to choose your services by pointing out the benefits and results the company made for the clients. The email also should contain a CTA for  "${callToActionValue} ". If it makes sense, please also reference the following outreach context in the first part of the email  "${outreachContextValue} "`, 
        'Follow-up Email': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a {input_1}. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Acknowledge the customer's issue or question, provide a helpful solution or response, and offer additional assistance or follow-up if needed. Write a 100-word follow-up email about the following issuequestion: ${emailValue}`, 
        'Referral Email Generator': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a marketing manager. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Please write an email to a recent customer to ask for a referral. The goal of the email is to persuade the customer to refer your ${productServiceNameValue} to their network. ${referralRewardValue}`, 
        'Subject Lines Generator': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a professional email marketer.. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Please provide me with ${totalSubjectLinesValue} engaging subject lines that are each 50 to 60 characters long for the following email  "${emailValue} ".`, 
        'Email Newsletter Calendar': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are a professional email marketer. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Please create an email newsletter calendar with ideas for engaging emails for ${totalMonthsValue} months based on  "${topicValue} ". There should be ${totalMonthsValue} email scheduled each week of the month. The emails should be entertaining, informative, and engaging. Please use simple and understandable words. Please include tips, personal experience, and fun facts. The markdown table should have actual dates in the future. Each month should have its table. The table columns should be: Date, Email Idea, Subject line. Please organize each email idea in the table so that it looks like a calendar. Do not self reference. Do not explain what you are doing. Reply back only with the table.`,
        'Generic Landing Page': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert landing page creator. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Create a landing page structure with ${totalHeadlines} attention grabbing headline about the topic: "${topicValue}". Please include the following keywords: "${keywordsValue}". Mention pain points to grab the attention of the visitor. Use the pain points mentioned to increase the click through rate for purchases and opt-ins. Please display the structure of the Landing page and include a title tag (between 50-60 characters) and meta description (between 120-130 characters). The landing page should have the flow of a story.`, 
        'Landing Page for Ecommerce': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert landing page creator for ecommerce websites. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Create an ecommerce focused landing page structure with ${totalHeadlines} attention grabbing headline for the "${productServiceValue}". Mention the unique selection of products with unique descriptions. Direct users to the buy now CTA for the products mentioned. Please include the following keywords: "${keywordsValue}". Please display the structure of the landing page and include a title tag (between 50-60 characters) and meta description (between 120-130 characters). The landing page should have the flow of a story.`, 
        'Landing Page for Affiliates': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert landing page creator for affiliate websites. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Create an affiliate marketing focused landing page structure with ${totalHeadlines} attention grabbing headline for "${productServiceValue}". Please include the following keywords: "${keywordsValue}". Include persuasive CTA's to affiliate links. Please display the structure of the landing page and include a title tag (between 50-60 characters) and meta description (between 120-130 characters). The landing page should have the flow of a story.`, 
        'Landing Page for SaaS Tool': `${promptTitle}Previous instruction should be ignored. Please respond only in the ${languagesValue} language. You are an expert landing page creator for SaaS tools. ${voicetonesValue && 'Use '+ voicetonesValue +' voice and tone.'} ${writingStylesValue && 'Use '+ writingStylesValue +' writing style.'} Do not self reference. Do not explain what you are doing. Create a SaaS focused landing page structure with ${totalHeadlines} attention grabbing headline for "${yourSaaSToolValue}". Please include the following keywords: "${keywordsValue}". Focus on the top features and benefits of the software. Include a comparison table of the SaaS tool comparing it to "${competitorsValue}". Please display the structure of the landing page and include a title tag (between 50-60 characters) and meta description (between 120-130 characters). The landing page should have the flow of a story.` 
    }
    let templateValue: any = copyWritingPrompt[props.templateValue] ? copyWritingPrompt[props.templateValue] : ''
    useEffect(() => {
        props.templateValue === 'Plot Generator' && setKeyword(bookDetailsValue);
        setTextareaValue(templateValue)
    }, [templateValue])
    useEffect(() => {
        resetValue()
    }, [props.templateValue])
    useEffect(() => {
        const data: any = []
        for (const key in indianLanguages) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = indianLanguages[key];
            data.push(value)
        }
        setIndianLanguagesData(data) 
        const dataVoice: any = []
        for (const key in voicetones) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = voicetones[key];
            dataVoice.push(value)
        }
        setVoicetonesData(dataVoice) 
        const datawritingStyles:any = []
        for (const key in writingStyles) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = writingStyles[key];
            datawritingStyles.push(value)
        }
        setwritingStylesData(datawritingStyles)
    },[])

    const onChangeRadio = (event: any) => {
        const value = event.target.value;
        setSelectedValue(value);
        setradioValue(value)
        if(value === 'Other languages'){
            const data: any = []
            for (const key in languages) {
                const value: any = {
                    name: '',
                    value: ''
                }
                value.value = key;
                value.name = languages[key];
                data.push(value)
            }
            setLanguagesData(data)
            setLanguagesValue('Arabic')
        } else {
            setLanguagesValue(value)
            setLanguagesData([])
        }
    }
    const onChangeLanguages = (event: any) => {
        const value = event.target.value;
        setLanguagesValue(value)
    }
    const onChangeVoicetones = (event: any) => {
        const value = event.target.value
        setVoicetonesValue(value)
    }
    const onChangeWritingStyles = (event: any) => {
        const value = event.target.value
        setwritingStylesValue(value)
    }
    const onChangeTopicValue = (event: any) => {
        const value = event.target.value
        setKeyword(value);
        setTopicValue(value)
    }
    const onChangeTotalTitles = (event: any) => {
        const value = event.target.value
        setTotalTitlesValue(value)
    }
    const onChangeFrameworkValue = (event: any) => {
        const value = event.target.value
        setFrameworkValue(value)
    }
    const onChangeKeywords = (event: any) => {
        const value = event.target.value
        setKeywordsValue(value)
    }
    const onChangeTextarea = (event: any) => {
        const value = event.target.value
        setTextareaValue(value)
    }
    const onChangeBlogpostOutline = (event: any) => {
        const value = event.target.value
        setKeyword(value);
        setBlogPostOutlineValue(value)
    }
    const onChangeTitle = (event: any) => {
        const value = event.target.value
        setKeyword(value);
        setTitleValue(value)
    }
    const onChangeBpTitle = (event: any) => {
        const value = event.target.value
        setBpTitleValue(value)
    }
    const onChangeTotalWords = (event: any) => {
        const value = event.target.value
        setTotalWordsValue(value)
    }
    const onChangeTotalDescriptions = (event: any ) => {
        const value = event.target.value 
        setKeyword(value);
        setTotalDescriptionsValue(value)
    }
    const onChangeTotalHeadlines = (event: any) => {
        const value = event.target.value 
        setKeyword(value);
        setTotalHeadlines(value)
    }
    const onChangePersona = (event: any) => {
        const value = event.target.value 
        setPersonaValue(value)
    }
    const onChangeBookDetails = (event: any) => {
        const value = event.target.value 
        setKeyword(value);
        setBookDetailsValue(value)
    }
    const onChangePlotSummary = (event: any) => {
        const value = event.target.value 
        setKeyword(value);
        setPlotSummaryValue(value)
    }
    const onChangeChapterSummary = (event: any) => {
        const value = event.target.value 
        setChapterSummaryValue(value)
    }
    const onChangeContent = (event: any) => {
        const value = event.target.value 
        setKeyword(value);
        setContentValue(value)
    }
    const onChangeArticleTitle = (event: any) => {
        const value = event.target.value 
        setArticleTitleValue(value)
    }
    const onChangeMainKeyword = (event: any) => {
        const value = event.target.value 
        setMainKeywordValue(value)
    }
    const onChangeTotalKeywords = (event:any) => {
        const value = event.target.value 
        setTotalKeywordsValue(value)
    }
    const onChangeTotalQuestions = (event:any) => {
        const value = event.target.value 
        setTotalQuestionsValue(value)
    }
    const onChangeArticlesPerWeek = (event: any) => {
        const value = event.target.value 
        setArticlesPerWeekValue(value)
    }
    const onChangeNumberofmonths = (event:any) => {
        const value = event.target.value 
        setNumberofmonthsValue(value)
    }
    const onChangenumberofFAQs = (event:any) => {
        const value = event.target.value 
        setNumberofFAQsValue(value)
    }
    const onChangeIndustry = (event:any) => {
        const value = event.target.value 
        setKeyword(value);
        setIndustryValue(value)
    }
    const onChangeTotalIdeas = (event:any) => {
        const value = event.target.value 
        setTotalIdeasValue(value)
    }
    const onChangeEducationLevel = (event:any) => {
        const value = event.target.value 
        setEducationLevelValue(value)
    }
    const onChangeCourseTopic = (event:any) => {
        const value = event.target.value 
        setKeyword(value);
        setCourseTopicValue(value)
    }
    const onChangeTotalSessions = (event:any) => {
        const value = event.target.value 
        setTotalSessionsValue(value)
    }
    const onChangeTotalModules = (event:any) => {
        const value = event.target.value 
        setTotalModulesValue(value)
    }
    const onChangeCourseLesson = (event:any) => {
        const value = event.target.value 
        setCourseLessonValue(value)
    }
    const onChangeTotalAssignments = (event:any) => {
        const value = event.target.value 
        setTotalAssignmentsValue(value)
    }
    const onChangeEmail = (event: any) => {
        const value = event.target.value 
        setKeyword(value);
        setEmailValue(value)
    }
    const onChangeProductService = (event:any) => {
        const value = event.target.value 
        setKeyword(value);
        setProductServiceValue(value)
    }
    const onChangeTotalSubjectLines = (event:any) => {
        const value = event.target.value 
        setTotalSubjectLinesValue(value)
    }
    const onChangeUseEmojis = (event:any) => {
        const value = event.target.value 
        setUseEmojisValue(value)
    }
    const onChangeCustomerName = (event: any) => {
        const value = event.target.value 
        setCustomerNameValue(value)
    }
    const onChangeCustomerCompany = (event: any) => {
        const value = event.target.value 
        setKeyword(value);
        setCustomerCompanyValue(value)
    }
    const onChangeOutreachContext = (event:any) => {
        const value = event.target.value 
        setOutreachContextValue(value)
    }
    const onChangeYourCompany = (event: any) => {
        const value = event.target.value 
        setYourCompanyValue(value)
    }
    const onChangeYourName = (event: any) => {
        const value = event.target.value  
        setYourNameValue(value)
    }
    const onChangeProductServiceName = (event: any) => {
        const value = event.target.value  
        setProductServiceNameValue(value)
    }
    const onChangeCallToAction = (event: any) => {
        const value = event.target.value  
        setCallToActionValue(value)
    }
    const onChangeProductServiceDescription = (event:any) => {
        const value = event.target.value  
        setKeyword(value);
        setProductServiceDescriptionValue(value)
    }
    const onChangeYourRole = (event:any) => {
        const value = event.target.value 
        setKeyword(value);
        setYourRoleValue(value)
    }
    const onChangeReferralReward = (event:any) => {
        const value = event.target.value 
        setReferralRewardValue(value)
    }
    const onChangeEmailsPerWeek = (event: any) => {
        const value = event.target.value 
        setEmailsPerWeekValue(value)
    }
    const onChangeTotalMonths = (event: any) => {
        const value = event.target.value 
        setTotalMonthsValue(value)
    }
    const onChangeYourSaaSTool = (event:any) => {
        const value = event.target.value 
        setYourSaaSToolValue(value)
    }
    const onChangeCompetitors = (event: any) => {
        const value = event.target.value 
        setKeyword(value);
        setCompetitorsValue(value)
    }
    const executeTemplateButton = () => {
        props.executeTemplateButton(textareaValue, keyword)
    }
    const getButtonVisiblety = () => {
        let keyvalue: any = []
        const value = props.templateValue
        switch(value) {
            case 'Generate Blog Post Titles':
                return topicValue && totalTitlesValue ? false : true;
            case 'Generate Blog Post Descriptions':
                return titleValue && totalDescriptionsValue ? false : true;
            case 'Generate Blog Post Outline': 
                return bpTitleValue && totalHeadlines ? false : true;
            case 'Generate Complete Blog Post From Outline':
                return blogpostOutlineValue ? false : true;
            case 'Generate Complete Blog Post From Topic':
                return topicValue && totalHeadlines ? false : true;
            case 'Generate Introduction Using Framework':
                return topicValue && totalWordsValue && keywordsValue && frameworkValue ? false : true;
            case 'Generate Paragraph Of Text':
                return topicValue && totalWordsValue && keywordsValue ? false : true; 
            case 'Plot Generator':
                return personaValue && bookDetailsValue ? false : true; 
            case 'Book Title Generator':
                return personaValue && plotSummaryValue && totalTitlesValue ? false : true; 
            case 'Book Outline Generator':
                return personaValue && plotSummaryValue && totalWordsValue ? false : true; 
            case 'Book Chapter Generator':
                return personaValue && plotSummaryValue && chapterSummaryValue ? false : true; 
            case 'Content Titles Generator':
                return topicValue && totalTitlesValue ? false : true;
            case 'Content Rewriter (Paste Content)':
                return contentValue ? false : true;
            case 'Content Brief Generator':
                return articleTitleValue && mainKeywordValue && totalKeywordsValue && totalQuestionsValue ? false : true;
            case 'Content Outline Generator':
                return articleTitleValue ? false : true;
            case 'Monthly Content Calendar':
                return topicValue && articlesPerWeekValue && numberofmonthsValue ? false : true;
            case 'FAQ Generator':
                return topicValue && numberofFAQsValue ? false : true;
            case 'Article to Listicle':
                return contentValue ? false : true;
            case 'Course Idea Generator':
                return industryValue && totalIdeasValue && educationLevelValue ? false : true;
            case 'Course Outline Generator':
                return courseTopicValue && totalSessionsValue && educationLevelValue ? false : true;
            case 'Complete Course Lesson':
                return courseTopicValue && courseLessonValue && totalModulesValue ? false : true;
            case 'Course Assignment Generator':
                return courseTopicValue && totalAssignmentsValue && educationLevelValue ? false : true
            case 'Professional Email Editor': 
                return emailValue ? false : true
            case 'Marketing Email Generator': 
                return productServiceValue && keywordsValue && totalSubjectLinesValue && useEmojisValue ? false : true
            case 'Sales Prospecting Email': 
                return yourCompanyValue && yourNameValue && customerNameValue && customerCompanyValue && outreachContextValue ? false : true 
            case 'Cold Outreach Email': 
                return productServiceValue && callToActionValue && productServiceDescriptionValue && outreachContextValue ? false : true
            case 'Follow-up Email': 
                return yourRoleValue && emailValue ? false : true
            case 'Referral Email Generator': 
                return productServiceNameValue && referralRewardValue ? false : true
            case 'Subject Lines Generator': 
                return totalSubjectLinesValue && emailValue ? false : true
            case 'Email Newsletter Calendar': 
                return topicValue && emailsPerWeekValue && totalMonthsValue ? false : true
            case 'Generic Landing Page': 
                return topicValue && totalHeadlines && keywordsValue ? false : true
            case 'Landing Page for Ecommerce': 
                return productServiceValue && totalHeadlines && keywordsValue ? false : true
            case 'Landing Page for Affiliates': 
            return productServiceValue && totalHeadlines && keywordsValue ? false : true
            case 'Landing Page for SaaS Tool': 
                return yourSaaSToolValue && keywordsValue && totalHeadlines && competitorsValue ? false : true
            default:
                return true;
        }
    }
    const resetValue = () => {
        setTotalTitlesValue(10)
        setFrameworkValue("AIDA (Attention Interest Desire Action)")
        setKeywordsValue('')
        setTopicValue('')
        setBlogPostOutlineValue('')
        setTitleValue('')
        setBpTitleValue('')
        setTotalWordsValue(200)
        setTotalDescriptionsValue(10)
        setTotalHeadlines(20)
        setPersonaValue('a top selling author of horror, supernatural fiction, suspense, crime, science fiction, and fantasy novels')
        setBookDetailsValue('The book teaches the readers how to get better at marketing ...')
        setPlotSummaryValue('')
        setChapterSummaryValue('')
        setContentValue('')
        setArticleTitleValue('')
       setMainKeywordValue('')
         setTotalKeywordsValue(10)
         setTotalQuestionsValue(10)
        setArticlesPerWeekValue(10)
        setNumberofmonthsValue(10)
        setNumberofFAQsValue(10)
        setIndustryValue('')
        setTotalIdeasValue(10)
        setEducationLevelValue('Primary')
        setCourseTopicValue('')
        setTotalSessionsValue(10)
        setTotalModulesValue(10)
        setCourseLessonValue('')
       setTotalAssignmentsValue(10)
        setEmailValue('')
        setProductServiceValue('')
      setTotalSubjectLinesValue(5)
       setUseEmojisValue('text and emojis')
       setCustomerNameValue('')
        setCustomerCompanyValue('')
        setOutreachContextValue('')
        setYourCompanyValue('')
        setYourNameValue('')
        setProductServiceNameValue('')
        setCallToActionValue('')
        setProductServiceDescriptionValue('')
        setYourRoleValue('Customer Service Agent')
        setReferralRewardValue('There is a monetary reward for the referal')
       setEmailsPerWeekValue(10)
        setTotalMonthsValue(10)
        setYourSaaSToolValue('')
        setCompetitorsValue('')
    }
    return (
        <div className="copywriting">
            <div className="row text-left">
                {/* <div className="col-sm-12 mt-2"><p>Description: Suggest 10 catchy blog post titles for a specific topic</p></div> */}
                <div className="col-sm-12 mt-2">
                    <div id="reverse-radio" className="mb-3">
                        {indianlanguagesData.map((item, i) => 
                            <Form.Check
                                key={i}
                                reverse
                                onChange={onChangeRadio}
                                label={item['name']}
                                checked={selectedValue === item['name']}
                                name="group1"
                                value={item['name']}
                                type='radio'
                                id={`reverse-radio-${i}`}
                            />
                        )}
                    </div>
                </div>
                {radioValue === "Other languages" && <div className="col-sm-4 mt-2">
                    <Form.Label htmlFor="languages">Languages:</Form.Label>
                    <Form.Select id="languages" 
                        value={languagesValue}
                        onChange={onChangeLanguages}
                    >
                        {languagesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>  
                </div>}
                {props.templateValue === 'Plot Generator' ||
                props.templateValue === 'Book Title Generator' ||
                props.templateValue === 'Book Outline Generator' ||
                props.templateValue === 'Book Chapter Generator' ? <></> : <><div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    <Form.Label htmlFor="voicetones">Voice Tones:</Form.Label>
                    <Form.Select 
                        id="voicetones" 
                        aria-label="Default"
                        value={voicetonesValue}
                        onChange={onChangeVoicetones}
                    >
                        <option>Default</option>
                        {voicetonesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>  
                </div>
                <div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    <Form.Label htmlFor="writingStyles">Writing Styles:</Form.Label>
                    <Form.Select 
                        id="writingStyles" 
                        aria-label="Default"
                        value={writingStylesValue}
                        onChange={onChangeWritingStyles}
                    >
                        <option>Default</option>
                        {writingStylesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>
                </div></>}
                {props.templateValue === 'Generate Blog Post Titles' ||
                props.templateValue === 'Generate Complete Blog Post From Topic' ||
                props.templateValue === 'Generate Introduction Using Framework' ||
                props.templateValue === 'Generate Paragraph Of Text' ||
                props.templateValue === 'Content Titles Generator' ||
                props.templateValue === 'Monthly Content Calendar' ||
                props.templateValue === 'FAQ Generator' || 
                props.templateValue === 'Email Newsletter Calendar' || 
                props.templateValue === "Generic Landing Page" ? <div className={"col-sm-" + (props.templateValue === 'Monthly Content Calendar' ? '6' : '10')+ "  mt-2"}> 
                    <Form.Label htmlFor="topic">Topics</Form.Label>
                    <Form.Control
                        value={topicValue}
                        type="text"
                        id="topic"
                        aria-describedby="Topic"
                        onChange={onChangeTopicValue} 
                    />                   
                </div> : <></>}
                {props.templateValue === 'Monthly Content Calendar' && <div className="col-sm-3  mt-2">
                    <Form.Label htmlFor="articlesPerWeek">Articles per week</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={articlesPerWeekValue}
                        id="articlesPerWeek"
                        aria-describedby="Articles per week"
                        onChange={onChangeArticlesPerWeek}
                    />
                </div>}
                {props.templateValue === 'Monthly Content Calendar' && <div className="col-sm-3  mt-2">
                    <Form.Label htmlFor="numberofmonths">Number of months</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={numberofmonthsValue}
                        id="numberofmonths"
                        aria-describedby="Number of months"
                        onChange={onChangeNumberofmonths}
                    />
                </div>}
                {props.templateValue === 'FAQ Generator' && <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="numberofFAQs">Number of FAQs</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={numberofFAQsValue}
                        id="numberofFAQs"
                        aria-describedby="Number of FAQs"
                        onChange={onChangenumberofFAQs}
                    />
                </div>}
                {props.templateValue === 'Generate Blog Post Descriptions' && <div className="col-sm-10  mt-2">
                    <Form.Label htmlFor="title">Title</Form.Label>
                    <Form.Control
                        value={titleValue}
                        type="text"
                        id="title"
                        aria-describedby="Title"
                        onChange={onChangeTitle} 
                    />
                </div>}
                {props.templateValue === 'Generate Blog Post Outline' && <div className="col-sm-10  mt-2">
                    <Form.Label htmlFor="bpTitle">Blog Post Title</Form.Label>
                    <Form.Control
                        value={bpTitleValue}
                        type="text"
                        id="bpTitle"
                        aria-describedby="Blog Post Title"
                        onChange={onChangeBpTitle} 
                    />
                </div>}
                {props.templateValue === 'Generate Blog Post Descriptions' && <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="totalDescriptions">Total Desc</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalDescriptionsValue}
                        id="totalDescriptions"
                        aria-describedby="Total Descriptions"
                        onChange={onChangeTotalDescriptions}
                    />
                </div>}
                {props.templateValue === 'Generate Blog Post Titles' || 
                props.templateValue === 'Book Title Generator' || 
                props.templateValue === 'Content Titles Generator' ? <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="totalTitles">Total Titles </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalTitlesValue}
                        id="totalTitles"
                        aria-describedby="Total Titles"
                        onChange={onChangeTotalTitles}
                    />
                </div> : <></>}
                {props.templateValue === 'Generate Introduction Using Framework' ||
                props.templateValue === 'Generate Paragraph Of Text' ||
                props.templateValue === 'Book Outline Generator' ? <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="totalTitles">Total Words: </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalWordsValue}
                        id="totalWords"
                        aria-describedby="Total Words"
                        onChange={onChangeTotalWords}
                    />
                </div> : <></>}
                {props.templateValue === 'Generate Complete Blog Post From Outline' && <div className='col-sm-12  mt-2'>
                    <Form.Label htmlFor="BPOtemplate">Blog Post Outline:</Form.Label>
                    <Form.Control 
                        id="BPOtemplate"
                        value={blogpostOutlineValue}
                        onChange={onChangeBlogpostOutline}
                        as='textarea' 
                        rows={2}
                    />
                </div>}
                {props.templateValue === "Sales Prospecting Email" && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="yourCompany">Your Company</Form.Label>
                    <Form.Control
                        value={yourCompanyValue}
                        type="text"
                        id="yourCompany"
                        aria-describedby="Your Company"
                        onChange={onChangeYourCompany} 
                    />
                </div>}
                {props.templateValue === 'Sales Prospecting Email' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="yourName">Your Name</Form.Label>
                    <Form.Control
                        value={yourNameValue}
                        type="text"
                        id="yourName"
                        aria-describedby="Your Name"
                        onChange={onChangeYourName} 
                    />
                </div>}
                {props.templateValue === "Cold Outreach Email" || props.templateValue === "Referral Email Generator" ? <div className={"col-sm-"+(props.templateValue === "Referral Email Generator" ? '12' : '6')+"  mt-2"}>
                    <Form.Label htmlFor="productServiceName">Product/Service Name </Form.Label>
                    <Form.Control
                        value={productServiceNameValue}
                        type="text"
                        id="productServiceName"
                        aria-describedby="Product/Service Name"
                        onChange={onChangeProductServiceName} 
                    />
                </div> : <></>}
                {props.templateValue === 'Cold Outreach Email' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="callToAction">Call To Action </Form.Label>
                    <Form.Control
                        value={callToActionValue}
                        type="text"
                        id="callToAction"
                        aria-describedby="Call To Action "
                        onChange={onChangeCallToAction} 
                    />
                </div>}
                {props.templateValue === 'Cold Outreach Email' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="productServiceDescription">Product/Service Description:</Form.Label>
                    <Form.Control 
                        id="productServiceDescription"
                        value={productServiceDescriptionValue}
                        as='textarea' 
                        rows={3}
                        onChange={onChangeProductServiceDescription}
                    />
                </div>} 
                {props.templateValue === 'Cold Outreach Email' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="outreachContext">Outreach Context: </Form.Label>
                    <Form.Control
                        value={outreachContextValue}
                        type="text"
                        id="outreachContext"
                        aria-describedby="Outreach Context"
                        onChange={onChangeOutreachContext} 
                    />
                </div>}
                {props.templateValue === 'Referral Email Generator' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="referralReward">Referral Reward </Form.Label>
                    <Form.Control
                        value={referralRewardValue}
                        type="text"
                        id="referralReward"
                        aria-describedby="Referral Reward"
                        onChange={onChangeReferralReward} 
                    />
                </div>}
                {props.templateValue === "Marketing Email Generator" ||
                props.templateValue === "Sales Prospecting Email" || 
                props.templateValue === "Landing Page for Ecommerce" ||
                props.templateValue === "Landing Page for Affiliates"  ? <div 
                    className={"col-sm-"+ (
                        props.templateValue !== "Landing Page for Ecommerce" && 
                        props.templateValue !== "Landing Page for Affiliates"  ? "12" : "10") +"  mt-2"}
                >
                    <Form.Label htmlFor="productService">Product/Service:</Form.Label>
                    <Form.Control 
                        id="productService"
                        value={productServiceValue}
                        as='textarea' 
                        rows={3}
                        onChange={onChangeProductService}
                    />
                </div> : <></>}
                {props.templateValue === "Landing Page for SaaS Tool" &&  <div className="col-sm-10  mt-2">
                    <Form.Label htmlFor="yourSaaSTool">Your SaaS Tool </Form.Label>
                    <Form.Control 
                        id="yourSaaSTool"
                        value={yourSaaSToolValue}
                        as='textarea' 
                        onChange={onChangeYourSaaSTool}
                        rows={3}
                    />
                </div>}
                {props.templateValue === 'Generate Blog Post Outline' || 
                props.templateValue === 'Generate Complete Blog Post From Topic' ||
                props.templateValue === "Generic Landing Page" || 
                props.templateValue === "Landing Page for Ecommerce" ||
                props.templateValue === "Landing Page for Affiliates" || props.templateValue === "Landing Page for SaaS Tool"  ? <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="totalHeadlines">Total Headlines</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalHeadlines}
                        id="totalHeadlines"
                        aria-describedby="Total Headlines"
                        onChange={onChangeTotalHeadlines}
                    />
                </div> : <></>}
                {props.templateValue === 'Generate Introduction Using Framework' ||
                props.templateValue === 'Generate Paragraph Of Text' || 
                props.templateValue === "Marketing Email Generator" || 
                props.templateValue === "Generic Landing Page" || 
                props.templateValue === "Landing Page for Ecommerce" || 
                props.templateValue === "Landing Page for Affiliates" ||
                props.templateValue === "Landing Page for SaaS Tool"  ? <div 
                    className={"col-sm-" + (
                        props.templateValue !== "Marketing Email Generator" && 
                        props.templateValue !== "Generic Landing Page" &&
                        props.templateValue !== "Landing Page for Ecommerce" &&
                        props.templateValue !== "Landing Page for Affiliates" && 
                        props.templateValue !== "Landing Page for SaaS Tool" ? '8' : '12') + "  mt-2"}
                >
                    <Form.Label htmlFor="ptemplate">Keywords:</Form.Label>
                    <Form.Control 
                        id="ptemplate"
                        value={keywordsValue}
                        as='textarea' 
                        rows={3}
                        onChange={onChangeKeywords}
                    />
                </div> : <></>}  
                {props.templateValue === 'Landing Page for SaaS Tool' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="competitors">Competitors</Form.Label>
                    <Form.Control 
                        id="competitors"
                        value={competitorsValue}
                        as='textarea' 
                        onChange={onChangeCompetitors}
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Generate Introduction Using Framework' ? <div className="col-sm-4">
                    <Form.Label htmlFor="writingStyles">Framework:</Form.Label>
                    <Form.Select 
                        id="writingStyles" 
                        aria-label="Default"
                        value={frameworkValue}
                        onChange={onChangeFrameworkValue}
                    >
                        <option>Select a Framework</option>
                        {framework.map((item, i) => <option key={i} value={item}>{item}</option>)}
                    </Form.Select>
                </div> : <></>}   
                {props.templateValue === 'Plot Generator' ||
                props.templateValue === 'Book Title Generator' ||
                props.templateValue === 'Book Outline Generator' ||
                props.templateValue === 'Book Chapter Generator' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="persona">Persona</Form.Label>
                    <Form.Control 
                        id="persona"
                        value={personaValue}
                        as='textarea' 
                        onChange={onChangePersona}
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Plot Generator' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="bookDetails">Book Details</Form.Label>
                    <Form.Control 
                        id="bookDetails"
                        value={bookDetailsValue}
                        as='textarea' 
                        onChange={onChangeBookDetails}
                        rows={3}
                    />
                </div>}
                {props.templateValue === 'Book Title Generator' ||
                props.templateValue === 'Book Outline Generator' ||
                props.templateValue === 'Book Chapter Generator' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="plotSummary">Plot Summary</Form.Label>
                    <Form.Control 
                        id="plotSummary"
                        value={plotSummaryValue}
                        as='textarea' 
                        onChange={onChangePlotSummary}
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Book Chapter Generator' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="chapterSummary">Chapter Summary</Form.Label>
                    <Form.Control 
                        id="chapterSummary"
                        value={chapterSummaryValue}
                        as='textarea' 
                        onChange={onChangeChapterSummary}
                        rows={3}
                    />
                </div>}
                {props.templateValue === 'Content Rewriter (Paste Content)' || 
                props.templateValue === 'Article to Listicle' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="content">Content </Form.Label>
                    <Form.Control 
                        id="content"
                        value={contentValue}
                        as='textarea' 
                        onChange={onChangeContent}
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Content Brief Generator' || 
                props.templateValue === 'Content Outline Generator' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="articleTitle">Article Title </Form.Label>
                    <Form.Control
                        value={articleTitleValue}
                        type="text"
                        id="articleTitle"
                        aria-describedby="Article Title"
                        onChange={onChangeArticleTitle} 
                    />
                </div> : <></>}
                {props.templateValue === 'Content Brief Generator' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="mainKeyword">Main Keyword </Form.Label>
                    <Form.Control
                        value={mainKeywordValue}
                        type="text"
                        id="mainKeyword"
                        aria-describedby="Article Title"
                        onChange={onChangeMainKeyword} 
                    />
                </div>}
                {props.templateValue === 'Content Brief Generator' && <div className="col-sm-3  mt-2">
                    <Form.Label htmlFor="totalKeywords">Total Keywords</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalKeywordsValue}
                        id="totalKeywords"
                        aria-describedby="Total Keywords"
                        onChange={onChangeTotalKeywords}
                    />
                </div>}
                {props.templateValue === 'Content Brief Generator' && <div className="col-sm-3  mt-2">
                    <Form.Label htmlFor="totalQuestions">Total Questions</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalQuestionsValue}
                        id="totalQuestions"
                        aria-describedby="Total Questions"
                        onChange={onChangeTotalQuestions}
                    />
                </div>}
                {props.templateValue === 'Course Idea Generator' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="industry">Industry </Form.Label>
                    <Form.Control
                        value={industryValue}
                        type="text"
                        id="industry"
                        aria-describedby="Industry"
                        onChange={onChangeIndustry} 
                    />
                </div>}
                {props.templateValue === 'Course Idea Generator' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="totalIdeas">Total Ideas </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalIdeasValue}
                        id="totalIdeas"
                        aria-describedby="Total Ideas"
                        onChange={onChangeTotalIdeas}
                    />
                </div>}
                {props.templateValue === "Marketing Email Generator" || props.templateValue === "Subject Lines Generator" ? <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="totalSubjectLines">Total Subject Lines </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalSubjectLinesValue}
                        id="totalSubjectLines"
                        aria-describedby="Total Subject Lines"
                        onChange={onChangeTotalSubjectLines}
                    />
                </div> : <></>}
                {props.templateValue === 'Marketing Email Generator' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="useEmojis">Use Emojis?:</Form.Label>
                    <Form.Select 
                        id="useEmojis" 
                        aria-label="Default"
                        value={useEmojisValue}
                        onChange={onChangeUseEmojis}
                    >
                        <option value="text and emojis">text and emojis</option>
                        <option value="text">text</option>
                    </Form.Select>
                </div>}
                
                {props.templateValue === "Sales Prospecting Email" && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="customerName">Customer Name</Form.Label>
                    <Form.Control
                        value={customerNameValue}
                        type="text"
                        id="customerName"
                        aria-describedby="Customer Name"
                        onChange={onChangeCustomerName} 
                    />
                </div>}
                {props.templateValue === 'Sales Prospecting Email' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="customerCompany">Customer Company</Form.Label>
                    <Form.Control
                        value={customerCompanyValue}
                        type="text"
                        id="customerCompany"
                        aria-describedby="Customer Company"
                        onChange={onChangeCustomerCompany} 
                    />
                </div>}
                {props.templateValue === 'Sales Prospecting Email' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="outreachContext">Outreach Context</Form.Label>
                    <Form.Control
                        value={outreachContextValue}
                        type="text"
                        id="outreachContext"
                        aria-describedby="Outreach Context"
                        onChange={onChangeOutreachContext} 
                    />
                </div>}
                {props.templateValue === 'Course Outline Generator' || 
                props.templateValue === 'Complete Course Lesson' || 
                props.templateValue === 'Course Assignment Generator' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="courseTopic">Course Topic </Form.Label>
                    <Form.Control
                        value={courseTopicValue}
                        type="text"
                        id="courseTopic"
                        aria-describedby="Industry"
                        onChange={onChangeCourseTopic} 
                    />
                </div> : <></>}
                {props.templateValue === 'Course Outline Generator' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="TotalSessions">Total Sessions </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalSessionsValue}
                        id="TotalSessions"
                        aria-describedby="Total Sessions"
                        onChange={onChangeTotalSessions}
                    />
                </div>}
                {props.templateValue === 'Course Assignment Generator' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="TotalAssignments">Total Assignments</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalAssignmentsValue}
                        id="TotalAssignments"
                        aria-describedby="Total Assignments"
                        onChange={onChangeTotalAssignments}
                    />
                </div>}
                {props.templateValue === 'Course Idea Generator' || 
                props.templateValue === 'Course Outline Generator' || 
                props.templateValue === 'Course Assignment Generator' ? <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="EducationLevel">Education Level:</Form.Label>
                    <Form.Select 
                        id="EducationLevel" 
                        aria-label="Default"
                        value={educationLevelValue}
                        onChange={onChangeEducationLevel}
                    >
                        <option>Select a Framework</option>
                        {education_lavel.map((item, i) => <option key={i} value={item}>{item}</option>)}
                    </Form.Select>
                </div> : <></>} 
                {props.templateValue === 'Complete Course Lesson' && <div className="col-sm-10  mt-2">
                    <Form.Label htmlFor="CourseLesson">Course Lesson </Form.Label>
                    <Form.Control 
                        id="CourseLesson"
                        value={courseLessonValue}
                        as='textarea' 
                        onChange={onChangeCourseLesson}
                        rows={3}
                    />
                </div>} 
                {props.templateValue === 'Complete Course Lesson' && <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="totalModules">Total Modules</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalModulesValue}
                        id="totalModules"
                        aria-describedby="Total Modules"
                        onChange={onChangeTotalModules}
                    />
                </div>} 
                {props.templateValue === 'Email Newsletter Calendar' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="emailsPerWeek">Emails per week</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={emailsPerWeekValue}
                        id="emailsPerWeek"
                        aria-describedby="Emails per week"
                        onChange={onChangeEmailsPerWeek}
                    />
                </div>}
                {props.templateValue === 'Email Newsletter Calendar' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="totalMonths">Total months</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalMonthsValue}
                        id="totalMonths"
                        aria-describedby="Total Months"
                        onChange={onChangeTotalMonths}
                    />
                </div>}
                {props.templateValue === 'Follow-up Email' && <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="yourRole">Your Role</Form.Label>
                    <Form.Select 
                        id="yourRole" 
                        aria-label="Default"
                        value={yourRoleValue}
                        onChange={onChangeYourRole}
                    >
                        <option value="Customer Service Agent">Customer Service Agent</option>
                        <option value="Sales Manager">Sales Manager</option>
                    </Form.Select>
                </div>} 
                {props.templateValue === 'Professional Email Editor' || 
                props.templateValue === "Follow-up Email" || 
                props.templateValue === "Subject Lines Generator" ? <div className="col-sm-10  mt-2">
                    <Form.Label htmlFor="email">Email </Form.Label>
                    <Form.Control 
                        id="email"
                        value={emailValue}
                        as='textarea' 
                        onChange={onChangeEmail}
                        rows={3}
                    />
                </div> : <></>} 
                {/* <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="ptemplate">Prompt Template</Form.Label>
                    <Form.Control 
                        id="ptemplate"
                        value={textareaValue}
                        onChange={onChangeTextarea}
                        as='textarea' 
                        rows={10}
                    />
                </div> */}
            </div>
            <div className="row">
                <div className="col-sm-12  mt-2">
                    <Button id="executeTemplate" 
                        onClick={executeTemplateButton} 
                        disabled={getButtonVisiblety()}
                    >
                        Execute Template
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Copywriting;

const indianLanguages: any = {
    "Assamese":"Assamese",
    "Bengali":"Bengali",
    "Bodo":"Bodo",
    "Dogri":"Dogri",
    "English": "English",
    "Gujarati":"Gujarati",
    "Hindi":"Hindi",
    "Kannada":"Kannada",
    "Kashmiri":"Kashmiri",
    "Konkani":"Konkani",
    "Maithili":"Maithili",
    "Malayalam":"Malayalam",
    "Marathi":"Marathi",
    "Meitei":"Meitei",
    "Nepali":"Nepali",
    "Odia":"Odia",
    "Punjabi":"Punjabi",
    "Sanskrit":"Sanskrit",
    "Santali":"Santali",
    "Sindhi":"Sindhi",
    "Tamil":"Tamil",
    "Telugu":"Telugu",
    "Urdu":"Urdu",
    "Other languages": "Other languages"
}

const languages: any = {
    "arabic": "Arabic",
    "bulgarian": "Bulgarian",
    "burmese": "Burmese",
    "czech": "Czech",
    "danish": "Danish",
    "dutch": "Dutch",
    "french": "French",
    "german": "German",
    "greek": "Greek",
    "hungarian": "Hungarian",
    "indonesian": "Indonesian",
    "italian": "Italian",
    "japanese": "Japanese",
    "javanese": "Javanese",
    "korean": "Korean",
    "mandarin chinese": "Mandarin Chinese",
    "polish": "Polish",
    "portuguese": "Portuguese",
    "romanian": "Romanian",
    "russian": "Russian",
    "spanish": "Spanish",
    "swedish": "Swedish",
    "thai": "Thai",
    "turkish": "Turkish",
    "ukranian": "Ukranian",
    "urdu": "Urdu",
    "vietnamese": "Vietnamese",
    "wu chinese": "Wu Chinese",
    "yue chinese": "Yue Chinese"
}

const voicetones: any = {
    "authoritative": "Authoritative",
    "caring": "Caring",
    "casual": "Casual",
    "cheerful": "Cheerful",
    "coarse": "Coarse",
    "conservative": "Conservative",
    "conversational": "Conversational",
    "creative": "Creative",
    "dry": "Dry",
    "edgy": "Edgy",
    "enthusiastic": "Enthusiastic",
    "expository": "Expository",
    "formal": "Formal",
    "frank": "Frank",
    "friendly": "Friendly",
    "fun": "Fun",
    "funny": "Funny",
    "humorous": "Humorous",
    "informative": "Informative",
    "irreverent": "Irreverent",
    "journalistic": "Journalistic",
    "matteroffact": "Matter of fact",
    "nostalgic": "Nostalgic",
    "objective": "Objective",
    "passionate": "Passionate",
    "poetic": "Poetic",
    "playful": "Playful",
    "professional": "Professional",
    "provocative": "Provocative",
    "quirky": "Quirky",
    "respectful": "Respectful",
    "romantic": "Romantic",
    "sarcastic": "Sarcastic",
    "serious": "Serious",
    "smart": "Smart",
    "snarky": "Snarky",
    "subjective": "Subjective",
    "sympathetic": "Sympathetic",
    "trendy": "Trendy",
    "trustworthy": "Trustworthy",
    "unapologetic": "Unapologetic",
    "upbeat": "Upbeat",
    "witty": "Witty"
}
const writingStyles: any = {
    "academic": "Academic",
    "analytical": "Analytical",
    "argumentative": "Argumentative",
    "conversational": "Conversational",
    "creative": "Creative",
    "critical": "Critical",
    "descriptive": "Descriptive",
    "epigrammatic": "Epigrammatic",
    "epistolary": "Epistolary",
    "expository": "Expository",
    "informative": "Informative",
    "instructive": "Instructive",
    "journalistic": "Journalistic",
    "metaphorical": "Metaphorical",
    "narrative": "Narrative",
    "persuasive": "Persuasive",
    "poetic": "Poetic",
    "satirical": "Satirical",
    "technical": "Technical"
}
const framework = ["AIDA (Attention Interest Desire Action)", "PAS (Problem Agitate Solution)", "BAB (Before After Bridge)", "FAB (Features Advantages Benefits)"]
const education_lavel = ['Primary','Middle','Secondary','University','MBA','MSC','MPhil','LLM','MRES','MA','GEM','MArch','MFA','BSC','BA','BFA','BCom','BBA','BTH']