import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

function Professionals(props: any){
    const [radioValue, setradioValue] = useState('English')
    const [selectedValue, setSelectedValue] = useState("English");
    const [languagesData, setLanguagesData] = useState([]);
    const [indianlanguagesData, setIndianLanguagesData] = useState([]);
    const [languagesValue, setLanguagesValue] = useState(radioValue);
    const [voicetonesData, setVoicetonesData] = useState([])
    const [voicetonesValue, setVoicetonesValue] = useState('')
    const [writingStylesData,setwritingStylesData] = useState([])
    const [writingStylesValue,setwritingStylesValue] = useState('')
    const [textareaValue, setTextareaValue] = useState('')
    const [companyDetailsValue,setcompanyDetailsValue] = useState('')
    const [accountRelatedTermsValue, setAccountRelatedTermsValue] = useState('')
    const [businessDescriptionValue, setbusinessDescriptionValue] = useState('')
    const [industryValue, setIndustryValue] = useState('')
    const [totalIdeasValue, setTotalIdeasValue] = useState(10)
    const [frameworkValue, setFrameworkValue] = useState("AIDA (Attention Interest Desire Action)")
    const [yourBusinessValue,setyourBusinessValue] = useState('')

    
    const [yourbusinessaboutValue,setYourbusinessaboutValue] = useState('')
    const [youdobusinessValue,setYoudobusinessValue] = useState('')
    const [likeinyourbusinessValue,setLikeinyourbusinessValue] = useState('')
    const [yourteamValue,setYourteam] = useState('')
    const [yourworkseemslow,setYourworkseemslow] = useState('')
    const [whattechnologyValue,setWhattechnologyValue] = useState('')
    const [jobRoleValue, setJobRoleValue] = useState('')
    const [investmentAmountValue,setInvestmentAmountValue] = useState(10000)
    const [goalValue, setGoalValue] = useState('')
    const [financeRelatedTermsValue, setfinanceRelatedTermsValue] = useState('')
    const [legalIssueValue, setLegalIssueValue] = useState('')
    const [totalCaseLawsValue, setTotalCaseLawsValue] = useState(10)
    const [countryValue,setcountryValue] = useState('United States')
    const [clientInfoValue, setclientInfoValue] = useState('')
    const [topicValue, setTopicValue] = useState('')
    const [locationValue, setLocationValue] = useState('California, United States')
    const [legalDocumentValue, setlegalDocumentValue] = useState('')
    const [firstPartyValue,setFirstPartyValue] = useState('')
    const [secondPartyValue,setSecondPartyValue] = useState('')
    const [jurisdictionValue,setJurisdictionValue] = useState('')
    const [keyTermsValue, setKeyTermsValue] = useState('')
    const [subjectValue, setSubjectValue] = useState('')
    const [totalQuestionsValue, setTotalQuestionsValue] = useState(10)
    const [studentAgeValue, setStudentAgeValue] = useState(14)
    const [quizTypeValue, setQuizTypeValue] = useState('')
    const [totalMonthsValue, setTotalMonthsValue] = useState(10)
    const [lessonsperweekValue,setlessonsperweekValue] = useState(5)
    const [keyword, setKeyword] = useState('')
    const promptTitle = `# Prompt by STW, Corp. - https://www.stw-ai.com/\nLines starting with # are only comments for humans\nYou must add at the top of the response \"_Created with [STW Prompt \"${props.templateValue}\"](https://www.stw-ai.com/)_\"\n---\n`
    let copyWritingPrompt: any ={
        'Financial Planning':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert corporate accountant.  Please come up with creative ways to manage finances. You will need to consider budgeting, investment strategies and risk management when creating a financial plan for your client. In some cases, you may also need to provide advice on taxation laws and regulations in order to help them maximize their profits. Please create a  financial plan for ${companyDetailsValue}. The plan should focus on cost savings and long-term investments. Please present results in a markdown table.`, 
        'Tax Accounting':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert tax accountant. You have been given the following business to analyze: "${companyDetailsValue}". First print the heading "Tax Audit Process Overview". Next, provide an overview of the tax audit process for this business. Next print the heading "Preparing for a Tax audit". After that explain how the business should prepare for the tax audit. Next pring the heading "Potential Tax Compliance Issues/Challenges". Now list all the potential tax compliance issues/challenges that the business might face along with the advice in a markdown table with the columns: compliance type, tax compliance issue, advice.`, 
        'Regulatory Compliance':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert Accountant. Please provide me with the Regulatory Compliance for "${companyDetailsValue}". Please present results in a markdown table with the columns: regulation category, status,  compliance requirements, key impact on company.`, 
        'Accounting Dictionary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert Accountant with extensive knowledge of all accounting terms. Please provide me with a simplified definition for the terms: ${accountRelatedTermsValue}. Please provide me with templates and real-world examples for the term so that I can better understand. Please present the results in a markdown table with the following columns: Accounting term, definition, real-world example.`, 
        'Business Auditor':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a seasoned entrepreneur. Your business is in need of an audit. Please prepare market research, SWOT analysis and potential challenges for "${businessDescriptionValue}". First print the heading "Market Research" and present the market research data in a markdown table. Then print the heading "SWOT Analysis" and present the SWOT analysis data in a markdown table. Finally print the heading "Potential Challenges" and present the challenges in an ordered list.`,  
        'Business Idea Generator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a successful and innovative entrepreneur. Please generate ${totalIdeasValue} unique business ideas for the market/industry: "${industryValue}". For each of the ideas first print out the business idea, and then generate a business plan in a markdown table with two columns and the following rows: target market, revenue model, marketing strategy. Then generate a markdown table with two columns: challenges and solutions`, 
        'Marketing Manager':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert marketing manager.  Write an ${frameworkValue} model to generate marketing strategy and sales for the following business: "${yourBusinessValue}". Please present the results in a markdown table with two column: stage, strategy and suggestions`,  
        'Business Advisor':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a consultant. Please advise ways to improve the efficiency of my business. Please present the results in a markdown table with the columns: area of improvement, strategy type, description. Based on the following information: \r\n\r\n1. What's your business about?\r\n   - ${yourbusinessaboutValue}\r\n\r\n2. How do you do business (online, in person, both)?\r\n   - ${youdobusinessValue} \r\n\r\n3. What does a usual day look like in your business?\r\n   - ${likeinyourbusinessValue}\r\n\r\n4. Who does what in your team?\r\n   - ${yourteamValue}\r\n\r\n5. What parts of your work seem slow or difficult?\r\n   - ${yourworkseemslow}\r\n\r\n6. What technology do you use in your business?\r\n   -  ${whattechnologyValue}  \r\n   \r\n   `, 
        'Investment Manager':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an investment manager. Can you please provide advice for a person with the following job role "${jobRoleValue}" who wants to invest ${investmentAmountValue} USD. Do mention the process and further explain complicated financial terms using analogies when necessary. Please also include the a markdown table with the following columns: Asset Class, Allocation, Amount (USD), Explanation.`, 
        'Excel Macro Helper':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert with Excel Macro for financial purposes. Please write a Macro that "${goalValue}"`, 
        'Financial Dictionary':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You have detailed financial literacy. Please provide me with a simplified definition for the terms: "${financeRelatedTermsValue}". Please provide me with templates and real-world examples for the term so that I can better understand. Please present the results in a markdown table with the following columns: Term, definition, real-world examples.`, 
        'Interview Questions Generator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a HR manager. Please generate interview questions for the job role: "${jobRoleValue}" for the company: "${companyDetailsValue}".  Please present the results in a markdown table with the Interview Question, Area of expertise, Category`, 
        'Employee Retention and Engagement':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a HR manager. Please generate ${totalIdeasValue} ideas to retain and engage employees for the company "${companyDetailsValue}". Please present results in a markdown table with the columns: idea, idea description, resources needed, estimated cost in USD.`, 
        'Employee Development Plan':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a HR manager. Please create an employee development plan for the job role: "${jobRoleValue}" at the company: "${companyDetailsValue}". Present the results in a timeline and include the following: department, development areas, goals, action steps, timeline (Q1-Q4)`, 
        'Employee Performance Evaluation':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a HR manager. Please generate an employee performance review evaluation for every department of the company: "${companyDetailsValue}"`, 
        'Case Law Generator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a lawyer's assistant. Please generate ${totalCaseLawsValue} case laws that should be used for the legal issue: "${legalIssueValue}" in the country: "${countryValue}". Please present the results in a markdown table with the following columns: Complete Date, Case, Citation, Court, Short Summary.`, 
        'Risk and Liabilities Generator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert lawyer. Please generate the potential risk and liabilities for the client: "${clientInfoValue}" in the country "${countryValue}". Please present the results in a markdown table with the following columns: Type of Infringement, Related Law, Potential Risk and Liabilities, Recommended Mitigation Measures.`, 
        'Legal Research Helper':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert lawyer. Please specify the best practices of legal research in "${topicValue} in "${locationValue}". Please present the results in a markdown table with the following columns: Type of law, Related law, Description of steps, Location.`, 
        'Legal Precedents ':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are an expert lawyer. Please provide an overview of the legal precedents of "${topicValue} in "${locationValue}". Please present the results in a markdown table with the following columns: Complete Date, Case, Citation, Court, Short Summary.`, 
        'Document Analyst':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a lawyer. You have a professional tone of voice. You have an informative writing style. Please review a legal document and provide actionable feedback on its legal implications. The legal document is : "${legalDocumentValue}".`,  
        'Drafting Settlement Agreement':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a lawyer. You have a professional tone of voice. You have an informative writing style. Please draft a settlement agreement between two parties - "${firstPartyValue}" and "${secondPartyValue}" involving the legal issue: "${legalIssueValue}" with the following terms: "${jurisdictionValue}" in the jurisdiction: "${keyTermsValue}".`, 
        'Quiz Creator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a teacher. Please create a ${totalQuestionsValue} question ${quizTypeValue} quiz for ${studentAgeValue} year old students, focused on the subject: "${subjectValue}".`, 
        'Subject Overview':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a teacher. Please provide an explanation and overview of the core topics for the subject: "${subjectValue}" for "${studentAgeValue}" year old students.  Please present the information in a markdown table with the following columns: core topic, explanation, class-based activity, further learning resources.`,  
        'Resource Generator':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. Do not explain what you are doing. Do not self reference. You are a teacher. Please suggest some tools or resources to help me create interactive presentations for my ${studentAgeValue} year old students for the subject: ${subjectValue}.  Please present the results in a markdown table with the following columns: Tool/resource, description, activity idea.`,  
        'Lesson Plan Calendar':`${promptTitle}Previous instruction should be ignored. Write in ${languagesValue} language for response. You are a teacher. Please create a ${totalMonthsValue} month long lesson plan calendar for ${studentAgeValue} year old students for the subject: ${subjectValue}. There should be ${lessonsperweekValue} lessons scheduled each week of the month. The lessons should aim at developing critical thinking, creative thinking, communication skill, analytical skills and research skills. Each lesson should have activity and homework. The markdown table should have actual dates in the future. Each month should have its own markdown table. The table columns should be: Date, Lesson, Activity, Homework. Please organize each lesson in the table so that it looks like a calendar. Do not self reference. Do not explain what you are doing. Reply back only with the table.`
    }
    let templateValue: any = copyWritingPrompt[props.templateValue] ? copyWritingPrompt[props.templateValue] : ''
    useEffect(() => {
        setTextareaValue(templateValue)
    }, [templateValue])
    useEffect(() => {
        resetValue()
    }, [props.templateValue])
    useEffect(() => {
        const data: any = []
        for (const key in indianLanguages) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = indianLanguages[key];
            data.push(value)
        }
        setIndianLanguagesData(data) 
        setLanguagesData(data) 
        const dataVoice: any = []
        for (const key in voicetones) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = voicetones[key];
            dataVoice.push(value)
        }
        setVoicetonesData(dataVoice) 
        const datawritingStyles:any = []
        for (const key in writingStyles) {
            const value: any = {
                name: '',
                value: ''
            }
            value.value = key;
            value.name = writingStyles[key];
            datawritingStyles.push(value)
        }
        setwritingStylesData(datawritingStyles)
    },[])
    const onChangeRadio = (event: any) => {
        const value = event.target.value;
        setSelectedValue(value);
        setradioValue(value)
        if(value === 'Other languages'){
            const data: any = []
            for (const key in languages) {
                const value: any = {
                    name: '',
                    value: ''
                }
                value.value = key;
                value.name = languages[key];
                data.push(value)
            }
            setLanguagesData(data)
            setLanguagesValue('Arabic')
        } else {
            setLanguagesValue(value)
            setLanguagesData([])
        }
    }
    const onChangeLanguages = (event: any) => {
        const value = event.target.value;
        setLanguagesValue(value)
    }
    const onChangeVoicetones = (event: any) => {
        const value = event.target.value
        setVoicetonesValue(value)
    }
    const onChangeWritingStyles = (event: any) => {
        const value = event.target.value
        setwritingStylesValue(value)
    }
    const onChangeTextarea = (event: any) => {
        const value = event.target.value
        setTextareaValue(value)
    }
    const onChangeCompanyDetails = (event:any) => {
        const value = event.target.value
        if(props.templateValue === "Interview Questions Generator" || props.templateValue === "Employee Development Plan"){
            const setValue = jobRoleValue ? `${value},${jobRoleValue}` : value
            setKeyword(setValue);
            setcompanyDetailsValue(value)
        } else {
            setKeyword(value);
            setcompanyDetailsValue(value)
        }
    }
    const onChangeAccountRelatedTerms = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setAccountRelatedTermsValue(value)
    }
    const onChangeBusinessDescription = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setbusinessDescriptionValue(value)
    }
    const onChangeIndustry = (event:any) => {
        const value = event.target.value 
        setKeyword(value);
        setIndustryValue(value)
    }
    const onChangeTotalIdeas = (event:any) => {
        const value = event.target.value 
        setTotalIdeasValue(value)
    }
    const onChangeFrameworkValue = (event: any) => {
        const value = event.target.value
        setFrameworkValue(value)
    }
    const onChangeYourBusiness = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setyourBusinessValue(value)
    }
    const onChangeyourbusinessabout = (event:any) => {
        const value = event.target.value
        setYourbusinessaboutValue(value)
    }
    const onChangeYoudobusiness = (event:any) => {
        const value = event.target.value
        setYoudobusinessValue(value)
    }
    const onChangeLikeinyourbusiness = (event:any) => {
        const value = event.target.value
        setLikeinyourbusinessValue(value)
    }
    const onChangeYourteam = (event:any) => {
        const value = event.target.value
        setYourteam(value)
    }
    const onChangeYourworkseemslow = (event:any) => {
        const value = event.target.value
        setYourworkseemslow(value)
    }
    const onChangeWhattechnology = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setWhattechnologyValue(value)
    }
    const onChangeJobRole = (event:any) => {
        const value = event.target.value 
        if(props.templateValue === "Interview Questions Generator" || props.templateValue === "Employee Development Plan"){
            const setValue = companyDetailsValue ? `${companyDetailsValue},${value}` : value
            setKeyword(setValue);
            setJobRoleValue(value)
        } else {
            setKeyword(value);
            setJobRoleValue(value)
        }
    }
    const onChangeInvestmentAmount = (event:any) => {
        const value = event.target.value 
        setInvestmentAmountValue(value)
    }
    const onChangeGoal = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setGoalValue(value)
    }
    const onChangeFinanceRelatedTerms = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setfinanceRelatedTermsValue(value)
    }
    const onChangeLegalIssue = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setLegalIssueValue(value)
    }
    const onChangeTotalCaseLaws = (event:any) => {
        const value = event.target.value
        setTotalCaseLawsValue(value)
    }
    const onChangeCountry = (event:any) => {
        const value = event.target.value
        setcountryValue(value)
    }
    const onChangeClientInfo = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setclientInfoValue(value)
    }
    const onChangeTopicValue = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setTopicValue(value)
    }
    const onChangeLocation = (event:any) => {
        const value = event.target.value
        setLocationValue(value)
    }
    const onChangeLegalDocument = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setlegalDocumentValue(value)
    }
    const onChangeFirstParty = (event:any) => {
        const value = event.target.value
        setFirstPartyValue(value)
    }
    const onChangeSecondParty = (event:any) => {
        const value = event.target.value
        setSecondPartyValue(value)
    }
    const onChangeJurisdiction = (event:any) => {
        const value = event.target.value
        setJurisdictionValue(value)
    }
    const onChangeKeyTerms = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setKeyTermsValue(value)
    }
    const onChangeSubject = (event:any) => {
        const value = event.target.value
        setKeyword(value);
        setSubjectValue(value)
    }
    const onChangeTotalQuestions = (event:any) => {
        const value = event.target.value
        setTotalQuestionsValue(value)
    }
    const onChangeStudentAge = (event:any) => {
        const value = event.target.value
        setStudentAgeValue(value)
    }
    const onChangeQuizType = (event:any) => {
        const value = event.target.value
        setQuizTypeValue(value)
    }
    const onChangeTotalMonths = (event: any) => {
        const value = event.target.value 
        setTotalMonthsValue(value)
    }
    const onChangeLessonsperweek = (event:any) => {
        const value = event.target.value 
        setlessonsperweekValue(value)
    }

    const executeTemplateButton = () => {
        props.executeTemplateButton(textareaValue, keyword)
    }
    const getButtonVisiblety = () => {
        const value = props.templateValue
        switch(value) {
            case 'Financial Planning':
                return companyDetailsValue ? false : true
            case 'Tax Accounting':
                return companyDetailsValue ? false : true
            case 'Regulatory Compliance':
                return companyDetailsValue ? false : true
            case 'Accounting Dictionary':
                return accountRelatedTermsValue ? false : true
            case 'Business Auditor':
                return businessDescriptionValue ? false : true
            case 'Business Idea Generator':
                return industryValue && totalIdeasValue ? false : true
            case 'Marketing Manager':
                return yourBusinessValue && frameworkValue ? false : true
            case 'Business Advisor':
                return yourbusinessaboutValue && youdobusinessValue && likeinyourbusinessValue && yourteamValue && yourworkseemslow && whattechnologyValue ? false : true
            case 'Investment Manager':
                return jobRoleValue ? false : true
            case 'Excel Macro Helper':
                return goalValue ? false : true
            case 'Financial Dictionary':
                return financeRelatedTermsValue ? false : true
            case 'Interview Questions Generator':
                return companyDetailsValue && jobRoleValue ? false : true
            case 'Employee Retention and Engagement':
                return companyDetailsValue && totalIdeasValue ? false : true
            case 'Employee Development Plan':
                return companyDetailsValue && jobRoleValue ? false : true
            case 'Employee Performance Evaluation':
                return companyDetailsValue ? false : true
            case 'Case Law Generator':
                return legalIssueValue && countryValue && totalCaseLawsValue ? false : true
            case 'Risk and Liabilities Generator':
                return clientInfoValue && countryValue ? false : true
            case 'Legal Research Helper':
                return topicValue && locationValue ? false : true
            case 'Legal Precedents':
                return topicValue && locationValue ? false : true
            case 'Document Analyst':
                return legalDocumentValue ? false : true
            case 'Drafting Settlement Agreement':
                return firstPartyValue && secondPartyValue && legalIssueValue && jurisdictionValue && keyTermsValue ? false : true
            case 'Quiz Creator':
                return subjectValue && totalQuestionsValue && studentAgeValue && quizTypeValue ? false : true
            case 'Subject Overview':
                return subjectValue && studentAgeValue ? false : true
            case 'Resource Generator':
                return subjectValue && studentAgeValue ? false : true
            case 'Lesson Plan Calendar':
                return subjectValue && studentAgeValue && totalMonthsValue && lessonsperweekValue ? false : true
            default:
                return true;
        }
    }
    const resetValue = () => {
        setcompanyDetailsValue('')
        setAccountRelatedTermsValue('')
        setbusinessDescriptionValue('')
        setIndustryValue('')
        setTotalIdeasValue(10)
        setFrameworkValue("AIDA (Attention Interest Desire Action)")
        setyourBusinessValue('')
        setYourbusinessaboutValue('')
        setYoudobusinessValue('')
        setLikeinyourbusinessValue('')
        setYourteam('')
        setYourworkseemslow('')
        setWhattechnologyValue('')
        setJobRoleValue('')
        setInvestmentAmountValue(10000)
        setGoalValue('')
        setfinanceRelatedTermsValue('')
        setLegalIssueValue('')
        setTotalCaseLawsValue(10)
        setcountryValue('United States')
        setclientInfoValue('')
        setTopicValue('')
        setLocationValue('California, United States')
        setlegalDocumentValue('')
        setFirstPartyValue('')
        setSecondPartyValue('')
        setJurisdictionValue('')
        setKeyTermsValue('')
        setSubjectValue('')
        setTotalQuestionsValue(10)
        setStudentAgeValue(14)
        setQuizTypeValue('')
        setTotalMonthsValue(10)
        setlessonsperweekValue(5)
    }
    return(
        <div className='marketing'>
            <div className="row">
                {/* <div className="col-sm-12 mt-2"><p>Description: Suggest 10 catchy blog post titles for a specific topic</p></div> */}
                <div className="col-sm-12 mt-2">
                    <div id="reverse-radio" className="mb-3">
                        {indianlanguagesData.map((item, i) => 
                            <Form.Check
                                key={i}
                                reverse
                                onChange={onChangeRadio}
                                label={item['name']}
                                checked={selectedValue === item['name']}
                                name="group1"
                                value={item['name']}
                                type='radio'
                                id={`reverse-radio-${i}`}
                            />
                        )}
                    </div>
                </div>
                {radioValue === "Other languages" && <div className="col-sm-4 mt-2">
                    <Form.Label htmlFor="languages">Languages:</Form.Label>
                    <Form.Select id="languages" 
                        value={languagesValue}
                        onChange={onChangeLanguages}
                    >
                        {languagesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>  
                </div>}
                <div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    {/* <Form.Label htmlFor="voicetones">Voice Tones:</Form.Label>
                    <Form.Select 
                        id="voicetones" 
                        aria-label="Default"
                        value={voicetonesValue}
                        onChange={onChangeVoicetones}
                    >
                        <option>Default</option>
                        {voicetonesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select>   */}
                </div>
                <div className={"col-sm-"+(radioValue === "Other languages" ? "4" : "6")+" mt-2"}>
                    {/* <Form.Label htmlFor="writingStyles">Writing Styles:</Form.Label>
                    <Form.Select 
                        id="writingStyles" 
                        aria-label="Default"
                        value={writingStylesValue}
                        onChange={onChangeWritingStyles}
                    >
                        <option>Default</option>
                        {writingStylesData.map((item, i) => <option key={i} value={item['name']}>{item['name']}</option>)}
                    </Form.Select> */}
                </div>
                {props.templateValue === 'Financial Planning' ||
                props.templateValue === 'Tax Accounting' ||
                props.templateValue === 'Regulatory Compliance' || 
                props.templateValue === "Interview Questions Generator" || 
                props.templateValue === "Employee Retention and Engagement" ||
                props.templateValue === "Employee Development Plan" || 
                props.templateValue === "Employee Performance Evaluation" ? <div className={"col-sm-"+(props.templateValue === "Employee Retention and Engagement" ? "10" : '12')+" mt-2"}>
                    {props.templateValue === "Interview Questions Generator" || 
                    props.templateValue === "Employee Retention and Engagement" || 
                    props.templateValue === "Employee Development Plan" || 
                    props.templateValue === "Employee Performance Evaluation" ? <><Form.Label htmlFor="CompanyDetails">Company Details</Form.Label>
                    <Form.Control 
                        id="CompanyDetails"
                        value={companyDetailsValue}
                        onChange={onChangeCompanyDetails}
                        type="text"
                    /></> : <><Form.Label htmlFor="CompanyDetails">Company Details</Form.Label>
                    <Form.Control 
                        id="CompanyDetails"
                        value={companyDetailsValue}
                        onChange={onChangeCompanyDetails}
                        as='textarea' 
                        rows={3}
                    /></>}
                </div> : <></>}
                {props.templateValue === "Legal Research Helper" || 
                props.templateValue === "Legal Precedents " ? <div className={"col-sm-12  mt-2"}> 
                    <Form.Label htmlFor="topic">Topics</Form.Label>
                    <Form.Control
                        value={topicValue}
                        type="text"
                        id="topic"
                        aria-describedby="Topic"
                        onChange={onChangeTopicValue} 
                    />                   
                </div> : <></>}
                {props.templateValue === 'Accounting Dictionary' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="AccountRelatedTerms">Account Related Terms</Form.Label>
                    <Form.Control 
                        id="AccountRelatedTerms"
                        value={accountRelatedTermsValue}
                        onChange={onChangeAccountRelatedTerms}
                        as='textarea' 
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Business Auditor' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="BusinessDescription">Business Description</Form.Label>
                    <Form.Control 
                        id="BusinessDescription"
                        value={businessDescriptionValue}
                        onChange={onChangeBusinessDescription}
                        as='textarea' 
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Document Analyst' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="LegalDocument">Legal Document</Form.Label>
                    <Form.Control 
                        id="LegalDocument"
                        value={legalDocumentValue}
                        onChange={onChangeLegalDocument}
                        as='textarea' 
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === "Drafting Settlement Agreement" ? <div className={"col-sm-6  mt-2"}> 
                    <Form.Label htmlFor="First Party">First Party</Form.Label>
                    <Form.Control
                        value={firstPartyValue}
                        type="text"
                        id="FirstParty"
                        aria-describedby="First Party"
                        onChange={onChangeFirstParty} 
                    />                   
                </div> : <></>}
                {props.templateValue === "Drafting Settlement Agreement" ? <div className={"col-sm-6  mt-2"}> 
                    <Form.Label htmlFor="SecondParty">Second Party</Form.Label>
                    <Form.Control
                        value={secondPartyValue}
                        type="text"
                        id="SecondParty"
                        aria-describedby="Topic"
                        onChange={onChangeSecondParty} 
                    />                   
                </div> : <></>}
                {props.templateValue === 'Case Law Generator' || 
                props.templateValue === "Drafting Settlement Agreement" ? <div className="col-sm-12  mt-2">
                    {props.templateValue === "Drafting Settlement Agreement" ? <><Form.Label htmlFor="LegalIssue">Legal Issue</Form.Label>
                    <Form.Control 
                        id="LegalIssue"
                        value={legalIssueValue}
                        onChange={onChangeLegalIssue}
                        type='text'
                    /></> : <><Form.Label htmlFor="LegalIssue">Legal Issue</Form.Label>
                    <Form.Control 
                        id="LegalIssue"
                        value={legalIssueValue}
                        onChange={onChangeLegalIssue}
                        as='textarea' 
                        rows={3}
                    /></>}
                </div> : <></>}
                {props.templateValue === "Drafting Settlement Agreement" ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="Jurisdiction">Jurisdiction</Form.Label>
                    <Form.Control 
                        id="Jurisdiction"
                        value={jurisdictionValue}
                        onChange={onChangeJurisdiction}
                        type='text'
                    />
                </div> : <></>}
                {props.templateValue === "Quiz Creator" || 
                props.templateValue === "Subject Overview" || 
                props.templateValue === "Resource Generator" || 
                props.templateValue === "Lesson Plan Calendar" ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="Subject">Subject</Form.Label>
                    <Form.Control 
                        id="Subject"
                        value={subjectValue}
                        onChange={onChangeSubject}
                        type='text'
                    />
                </div> : <></>}
                {props.templateValue === "Quiz Creator" ? <div className="col-sm-4  mt-2">
                    <Form.Label htmlFor="TotalQuestions">Total Questions</Form.Label>
                    <Form.Control 
                        id="TotalQuestions"
                        value={totalQuestionsValue}
                        onChange={onChangeTotalQuestions}
                        type="number"
                        min={1}
                        max={1000}
                    />
                </div> : <></>}
                {props.templateValue === "Quiz Creator" || 
                props.templateValue === "Subject Overview" || 
                props.templateValue === "Resource Generator" || 
                props.templateValue === "Lesson Plan Calendar" ? <div className="col-sm-4  mt-2">
                    <Form.Label htmlFor="StudentAge">Student Age</Form.Label>
                    <Form.Control 
                        id="StudentAge"
                        value={studentAgeValue}
                        onChange={onChangeStudentAge}
                        type="number"
                        min={1}
                        max={1000}
                    />
                </div> : <></>}
                {props.templateValue === "Lesson Plan Calendar" && <div className="col-sm-4  mt-2">
                    <Form.Label htmlFor="totalMonths">Total months</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalMonthsValue}
                        id="totalMonths"
                        aria-describedby="Total Months"
                        onChange={onChangeTotalMonths}
                    />
                </div>}
                {props.templateValue === "Lesson Plan Calendar" && <div className="col-sm-4  mt-2">
                    <Form.Label htmlFor="Lessonsperweek">Lessons per week</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={lessonsperweekValue}
                        id="Lessonsperweek"
                        aria-describedby="Total Months"
                        onChange={onChangeLessonsperweek}
                    />
                </div>}
                {props.templateValue === "Quiz Creator" ? <div className="col-sm-4  mt-2">
                    <Form.Label htmlFor="QuizType">Quiz Type</Form.Label>
                    <Form.Select 
                        id="QuizType"
                        value={quizTypeValue}
                        onChange={onChangeQuizType}
                    >
                        <option value="Multiple choice">Multiple choice</option>
                        <option value="True or False">True or False</option>
                        <option value="Fill in the blank">Fill in the blank</option>
                    </Form.Select>
                </div> : <></>}
                {props.templateValue === "Drafting Settlement Agreement" ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="KeyTerms ">Key Terms </Form.Label>
                    <Form.Control 
                        id="KeyTerms "
                        value={keyTermsValue}
                        onChange={onChangeKeyTerms }
                        as="textarea"
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Risk and Liabilities Generator' ? <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="ClientInfo">Client Info</Form.Label>
                    <Form.Control 
                        id="ClientInfo"
                        value={clientInfoValue}
                        onChange={onChangeClientInfo}
                        as='textarea' 
                        rows={3}
                    />
                </div> : <></>}
                {props.templateValue === 'Case Law Generator' || 
                props.templateValue === 'Risk and Liabilities Generator' ? <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="Country">Country</Form.Label>
                    <Form.Control
                        value={countryValue}
                        type="text"
                        id="Country"
                        aria-describedby="Country"
                        onChange={onChangeCountry} 
                    />
                </div> : <></>}
                {props.templateValue === 'Legal Research Helper' || 
                props.templateValue === "Legal Precedents " ? <div className="col-sm-6  mt-2">
                    <Form.Label htmlFor="Location">Location</Form.Label>
                    <Form.Control
                        value={locationValue}
                        type="text"
                        id="Location"
                        aria-describedby="Location"
                        onChange={onChangeLocation} 
                    />
                </div> : <></>}
                {props.templateValue === 'Business Idea Generator' && <div className="col-sm-10  mt-2">
                    <Form.Label htmlFor="industry">Market/Industry </Form.Label>
                    <Form.Control
                        value={industryValue}
                        type="text"
                        id="industry"
                        aria-describedby="Industry"
                        onChange={onChangeIndustry} 
                    />
                </div>}
                {props.templateValue === 'Marketing Manager' && <div className="col-sm-9  mt-2">
                    <Form.Label htmlFor="YourBusiness">Your Business</Form.Label>
                    <Form.Control
                        value={yourBusinessValue}
                        type="text"
                        id="YourBusiness"
                        aria-describedby="Your Business"
                        onChange={onChangeYourBusiness} 
                    />
                </div>}
                {props.templateValue === 'Case Law Generator' ? <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="TotalCaseLaws ">Total Case Laws </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalCaseLawsValue}
                        id="TotalCaseLaws"
                        aria-describedby="Total Case Laws"
                        onChange={onChangeTotalCaseLaws}
                    />
                </div>:<></>}
                {props.templateValue === 'Business Idea Generator' ||
                props.templateValue === "Employee Retention and Engagement" ? <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="totalIdeas">Total Ideas </Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={totalIdeasValue}
                        id="totalIdeas"
                        aria-describedby="Total Ideas"
                        onChange={onChangeTotalIdeas}
                    />
                </div>:<></>}
                {props.templateValue === 'Marketing Manager' ? <div className="col-sm-3 mt-2">
                    <Form.Label htmlFor="writingStyles">Framework:</Form.Label>
                    <Form.Select 
                        id="writingStyles" 
                        aria-label="Default"
                        value={frameworkValue}
                        onChange={onChangeFrameworkValue}
                    >
                        <option>Select a Framework</option>
                        {framework.map((item, i) => <option key={i} value={item}>{item}</option>)}
                    </Form.Select>
                </div> : <></>}
                {props.templateValue === 'Excel Macro Helper' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="goal">Goal</Form.Label>
                    <Form.Control 
                        id="goal"
                        value={goalValue}
                        onChange={onChangeGoal}
                        as='textarea' 
                        rows={3}
                    />
                </div>}
                {props.templateValue === 'Financial Dictionary' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="FinanceRelatedTerms">Finance Related Terms</Form.Label>
                    <Form.Control 
                        id="FinanceRelatedTerms"
                        value={financeRelatedTermsValue}
                        onChange={onChangeFinanceRelatedTerms}
                        as='textarea' 
                        rows={3}
                    />
                </div>}
                {props.templateValue === 'Investment Manager' || 
                props.templateValue === "Interview Questions Generator" || 
                props.templateValue === "Employee Development Plan" ? <div className={"col-sm-"+(props.templateValue === "Interview Questions Generator" ? "12" : "10")+" mt-2"}>
                    {props.templateValue === "Interview Questions Generator" || 
                    props.templateValue === "Employee Development Plan" ? <><Form.Label htmlFor="JobRole">Job Role</Form.Label>
                    <Form.Control
                        value={jobRoleValue}
                        type='text'
                        id="JobRole"
                        aria-describedby="JobRole"
                        onChange={onChangeJobRole} 
                    /></> : <><Form.Label htmlFor="JobRole">Job Role / Background</Form.Label>
                    <Form.Control
                        value={jobRoleValue}
                        as='textarea'
                        rows={3}
                        id="JobRole"
                        aria-describedby="JobRole"
                        onChange={onChangeJobRole} 
                    /></>}
                </div> : <></>}
                {props.templateValue === 'Investment Manager' && <div className="col-sm-2  mt-2">
                    <Form.Label htmlFor="InvestmentAmount">Investment Amount (USD)</Form.Label>
                    <Form.Control
                        type="number"
                        min={1}
                        max={1000}
                        value={investmentAmountValue}
                        id="InvestmentAmount"
                        aria-describedby="Investment Amount"
                        onChange={onChangeInvestmentAmount}
                    />
                </div>}
                {props.templateValue === 'Business Advisor' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="yourbusinessabout">What is your business about?</Form.Label>
                    <Form.Control
                        value={yourbusinessaboutValue}
                        type="text"
                        id="yourbusinessabout"
                        aria-describedby="What is your business about?"
                        onChange={onChangeyourbusinessabout} 
                    />
                </div>}
                {props.templateValue === 'Business Advisor' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="youdobusiness">How do you do business (online, in person, both)</Form.Label>
                    <Form.Control
                        value={youdobusinessValue}
                        type="text"
                        id="youdobusiness"
                        aria-describedby="How do you do business (online, in person, both)"
                        onChange={onChangeYoudobusiness} 
                    />
                </div>}
                {props.templateValue === 'Business Advisor' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="likeinyourbusiness">What does a usual day look like in your business? </Form.Label>
                    <Form.Control
                        value={likeinyourbusinessValue}
                        type="text"
                        id="likeinyourbusiness"
                        aria-describedby="What does a usual day look like in your business?"
                        onChange={onChangeLikeinyourbusiness} 
                    />
                </div>}
                {props.templateValue === 'Business Advisor' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="yourteam">Who does what in your team?</Form.Label>
                    <Form.Control
                        value={yourteamValue}
                        type="text"
                        id="yourteam"
                        aria-describedby="Who does what in your team?"
                        onChange={onChangeYourteam} 
                    />
                </div>}
                {props.templateValue === 'Business Advisor' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="yourworkseemslow">What parts of your work seem slow or difficult?</Form.Label>
                    <Form.Control
                        value={yourworkseemslow}
                        type="text"
                        id="yourworkseemslow"
                        aria-describedby="What parts of your work seem slow or difficult?"
                        onChange={onChangeYourworkseemslow} 
                    />
                </div>}
                {props.templateValue === 'Business Advisor' && <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="Whattechnology">What technology do you use in your business?</Form.Label>
                    <Form.Control
                        value={whattechnologyValue}
                        type="text"
                        id="Whattechnology"
                        aria-describedby="What technology do you use in your business?"
                        onChange={onChangeWhattechnology} 
                    />
                </div>}
                {/* <div className="col-sm-12  mt-2">
                    <Form.Label htmlFor="ptemplate">Prompt Template</Form.Label>
                    <Form.Control 
                        id="ptemplate"
                        value={textareaValue}
                        onChange={onChangeTextarea}
                        as='textarea' 
                        rows={10}
                    />
                </div> */}
            </div>
            <div className="row">
                <div className="col-sm-12  mt-2">
                    <Button id="executeTemplate" onClick={executeTemplateButton} disabled={getButtonVisiblety()} >Execute Template</Button>
                </div>
            </div>
        </div>
    )   
}

export default Professionals;


const indianLanguages: any = {
    "Assamese":"Assamese",
    "Bengali":"Bengali",
    "Bodo":"Bodo",
    "Dogri":"Dogri",
    "English": "English",
    "Gujarati":"Gujarati",
    "Hindi":"Hindi",
    "Kannada":"Kannada",
    "Kashmiri":"Kashmiri",
    "Konkani":"Konkani",
    "Maithili":"Maithili",
    "Malayalam":"Malayalam",
    "Marathi":"Marathi",
    "Meitei":"Meitei",
    "Nepali":"Nepali",
    "Odia":"Odia",
    "Punjabi":"Punjabi",
    "Sanskrit":"Sanskrit",
    "Santali":"Santali",
    "Sindhi":"Sindhi",
    "Tamil":"Tamil",
    "Telugu":"Telugu",
    "Urdu":"Urdu",
    "Other languages": "Other languages"
}

const languages: any = {
    "arabic": "Arabic",
    "bulgarian": "Bulgarian",
    "burmese": "Burmese",
    "czech": "Czech",
    "danish": "Danish",
    "dutch": "Dutch",
    "french": "French",
    "german": "German",
    "greek": "Greek",
    "hungarian": "Hungarian",
    "indonesian": "Indonesian",
    "italian": "Italian",
    "japanese": "Japanese",
    "javanese": "Javanese",
    "korean": "Korean",
    "mandarin chinese": "Mandarin Chinese",
    "polish": "Polish",
    "portuguese": "Portuguese",
    "romanian": "Romanian",
    "russian": "Russian",
    "spanish": "Spanish",
    "swedish": "Swedish",
    "thai": "Thai",
    "turkish": "Turkish",
    "ukranian": "Ukranian",
    "urdu": "Urdu",
    "vietnamese": "Vietnamese",
    "wu chinese": "Wu Chinese",
    "yue chinese": "Yue Chinese"
}

const voicetones: any = {
    "authoritative": "Authoritative",
    "caring": "Caring",
    "casual": "Casual",
    "cheerful": "Cheerful",
    "coarse": "Coarse",
    "conservative": "Conservative",
    "conversational": "Conversational",
    "creative": "Creative",
    "dry": "Dry",
    "edgy": "Edgy",
    "enthusiastic": "Enthusiastic",
    "expository": "Expository",
    "formal": "Formal",
    "frank": "Frank",
    "friendly": "Friendly",
    "fun": "Fun",
    "funny": "Funny",
    "humorous": "Humorous",
    "informative": "Informative",
    "irreverent": "Irreverent",
    "journalistic": "Journalistic",
    "matteroffact": "Matter of fact",
    "nostalgic": "Nostalgic",
    "objective": "Objective",
    "passionate": "Passionate",
    "poetic": "Poetic",
    "playful": "Playful",
    "professional": "Professional",
    "provocative": "Provocative",
    "quirky": "Quirky",
    "respectful": "Respectful",
    "romantic": "Romantic",
    "sarcastic": "Sarcastic",
    "serious": "Serious",
    "smart": "Smart",
    "snarky": "Snarky",
    "subjective": "Subjective",
    "sympathetic": "Sympathetic",
    "trendy": "Trendy",
    "trustworthy": "Trustworthy",
    "unapologetic": "Unapologetic",
    "upbeat": "Upbeat",
    "witty": "Witty"
}
const writingStyles: any = {
    "academic": "Academic",
    "analytical": "Analytical",
    "argumentative": "Argumentative",
    "conversational": "Conversational",
    "creative": "Creative",
    "critical": "Critical",
    "descriptive": "Descriptive",
    "epigrammatic": "Epigrammatic",
    "epistolary": "Epistolary",
    "expository": "Expository",
    "informative": "Informative",
    "instructive": "Instructive",
    "journalistic": "Journalistic",
    "metaphorical": "Metaphorical",
    "narrative": "Narrative",
    "persuasive": "Persuasive",
    "poetic": "Poetic",
    "satirical": "Satirical",
    "technical": "Technical"
}
const framework = ["AIDA (Attention Interest Desire Action)", "PAS (Problem Agitate Solution)", "BAB (Before After Bridge)", "FAB (Features Advantages Benefits)"]